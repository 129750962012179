import {
    PAGINATE_LEVEL_COMISSION_LIST,
    PAGINATE_LEVEL_COMISSION_LIST_SUCCESS,
    FILTER_PAGINATE_LEVEL_COMISSION_LIST,
    FILTER_PAGINATE_LEVEL_COMISSION_LIST_SUCCESS,
    DELETE_LEVEL_COMISSION_LIST_SUCCESS,
    DELETE_LEVEL_COMISSION_LIST,
    API_ERROR,
} from "./actionTypes"


export const filtersAllLevelComissionListPaginate = params => {
    return {
        type: FILTER_PAGINATE_LEVEL_COMISSION_LIST,
        payload: {params},
    }
}

export const filtersAllLevelComissionListPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_LEVEL_COMISSION_LIST_SUCCESS,
        payload: {params},
    }
}

export const allLevelComissionListPaginate = params => {
    return {
        type: PAGINATE_LEVEL_COMISSION_LIST,
        payload: {params},
    }
}

export const allLevelComissionListPaginateSuccess = result => {
    return {
        type: PAGINATE_LEVEL_COMISSION_LIST_SUCCESS,
        payload: {result},
    }
}

export const deleteLevelComissionList = (id, history) => {
  return {
    type: DELETE_LEVEL_COMISSION_LIST,
    payload: {id, history} ,
  }
}

export const deleteLevelComissionListSuccess = message => {
    return {
        type: DELETE_LEVEL_COMISSION_LIST_SUCCESS,
        payload: message,
    }
}

export const apiPaginateLevelComissionListError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

