export const CREATE_LOYALTY_TRANSACTION = "CREATE_LOYALTY_TRANSACTION"
export const CREATE_LOYALTY_TRANSACTION_SUCCESS = "CREATE_LOYALTY_TRANSACTION_SUCCESS"

export const EDIT_LOYALTY_TRANSACTION = "EDIT_LOYALTY_TRANSACTION"
export const EDIT_LOYALTY_TRANSACTION_SUCCESS = "EDIT_LOYALTY_TRANSACTION_SUCCESS"

export const FIND_BY_ID_LOYALTY_TRANSACTION = "FIND_BY_ID_LOYALTY_TRANSACTION"
export const FIND_BY_ID_LOYALTY_TRANSACTION_SUCCESS = "FIND_BY_ID_LOYALTY_TRANSACTION_SUCCESS"

export const CREATE_OR_EDIT_LOYALTY_TRANSACTION_API_ERROR = "CREATE_OR_EDIT_LOYALTY_TRANSACTION_API_ERROR"
