import {
    CREATE_LOYALTY_TRANSACTION,
    CREATE_LOYALTY_TRANSACTION_SUCCESS,
    EDIT_LOYALTY_TRANSACTION,
    EDIT_LOYALTY_TRANSACTION_SUCCESS,
    FIND_BY_ID_LOYALTY_TRANSACTION,
    FIND_BY_ID_LOYALTY_TRANSACTION_SUCCESS,
    CREATE_OR_EDIT_LOYALTY_TRANSACTION_API_ERROR
} from "./actionTypes"


const initialState = {
    loading: false,
    loading_form: false,
    existsFiles: false,
    error: false,
    message: '',


    transaction_id: 0,
    status: 1,
    order_id: 0,
    type: 1,
    level_comission_id: 0,
    price: 0,
    customer_id: 0,
    id: 0,

}

const createOrEditLoyaltyTransaction = (state = initialState, action) => {

    switch (action.type) {
        case CREATE_LOYALTY_TRANSACTION:
            state = {
                ...state,
                loading: true
            }
            break
        case CREATE_LOYALTY_TRANSACTION_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                id: action.payload.id,
                existsFiles: action.payload.existsFiles,
                loading: false
            }
            break
        case EDIT_LOYALTY_TRANSACTION:
            state = { ...state, loading: true }
            break
        case EDIT_LOYALTY_TRANSACTION_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case FIND_BY_ID_LOYALTY_TRANSACTION:
            state = { ...state, loading: false, loading_form: true }
            break
        case FIND_BY_ID_LOYALTY_TRANSACTION_SUCCESS:
            state = {
                ...state,
                loading: false,
                loading_form: false,

                transaction_id: action.payload.result.transaction_id,
                status: action.payload.result.status,
                order_id: action.payload.result.order_id,
                type: action.payload.result.type,
                level_comission_id: action.payload.result.level_comission_id,
                price: action.payload.result.price,
                customer_id: action.payload.result.customer_id,
                id: action.payload.result.id,

            }
        case CREATE_OR_EDIT_LOYALTY_TRANSACTION_API_ERROR:
            state = { ...state, loading_form: false, error: action.payload, loading: false, existsFiles: false }
            break
        default:
            state = { ...state, loading_form: false, loading: false }
            break
    }

    return state
}
export default createOrEditLoyaltyTransaction
