import {
    CREATE_PRODUCTS_PRICE_LIST,
    CREATE_PRODUCTS_PRICE_LIST_SUCCESS,
    EDIT_PRODUCTS_PRICE_LIST,
    EDIT_PRODUCTS_PRICE_LIST_SUCCESS,
    FIND_BY_ID_PRODUCTS_PRICE_LIST,
    FIND_BY_ID_PRODUCTS_PRICE_LIST_SUCCESS,
    CREATE_OR_EDIT_PRODUCTS_PRICE_LIST_API_ERROR
} from "./actionTypes"


const initialState = {
    loading: false,
    loading_form: false,
    existsFiles: false,
    error: false,
    message: '',

    status: 1,
    price: null,
    id: 0,

    company: null,
    product: null,
    price_list: null,
   

}

const createOrEditProductsPriceList = (state = initialState, action) => {

    switch (action.type) {
        case CREATE_PRODUCTS_PRICE_LIST:
            state = {
                ...state,
                loading: true
            }
            break
        case CREATE_PRODUCTS_PRICE_LIST_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                id: action.payload.id,
                existsFiles: action.payload.existsFiles,
                loading: false
            }
            break
        case EDIT_PRODUCTS_PRICE_LIST:
            state = { ...state, loading: true }
            break
        case EDIT_PRODUCTS_PRICE_LIST_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case FIND_BY_ID_PRODUCTS_PRICE_LIST:
            state = { ...state, loading: false, loading_form: true }
            break
        case FIND_BY_ID_PRODUCTS_PRICE_LIST_SUCCESS:
            state = {
                ...state,
                loading: false,
                loading_form: false,
                company: action.payload.result.company.text,
                product: action.payload.result.product.text,
                price_list: action.payload.result.price_list.text,
                id: action.payload.result.id,
                price: action.payload.result.price,

            }
        case CREATE_OR_EDIT_PRODUCTS_PRICE_LIST_API_ERROR:
            state = { ...state, loading_form: false, error: action.payload, loading: false, existsFiles: false }
            break
        default:
            state = { ...state, loading_form: false, loading: false }
            break
    }

    return state
}
export default createOrEditProductsPriceList
