export const PAGINATE_LEVEL_COMISSION_LIST = "PAGINATE_LEVEL_COMISSION_LIST"
export const PAGINATE_LEVEL_COMISSION_LIST_SUCCESS = "PAGINATE_LEVEL_COMISSION_LIST_SUCCESS"

export const FILTER_PAGINATE_LEVEL_COMISSION_LIST = "FILTER_PAGINATE_LEVEL_COMISSION_LIST"
export const FILTER_PAGINATE_LEVEL_COMISSION_LIST_SUCCESS = "FILTER_PAGINATE_LEVEL_COMISSION_LIST_SUCCESS"

export const DELETE_LEVEL_COMISSION_LIST = "DELETE_LEVEL_COMISSION_LIST"
export const DELETE_LEVEL_COMISSION_LIST_SUCCESS = "DELETE_LEVEL_COMISSION_LIST_SUCCESS"

export const API_ERROR = "PAGINATE_LEVEL_COMISSION_LIST_API_ERROR"
