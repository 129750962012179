import {
    PAGINATE_PRODUCTS,
    PAGINATE_PRODUCTS_SUCCESS,
    FILTER_PAGINATE_PRODUCTS,
    FILTER_PAGINATE_PRODUCTS_SUCCESS,
    DELETE_PRODUCTS_SUCCESS,
    DELETE_PRODUCTS,
    API_ERROR,
} from "./actionTypes"


export const filtersAllProductPaginate = params => {
    return {
        type: FILTER_PAGINATE_PRODUCTS,
        payload: {params},
    }
}

export const filtersAllProductPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_PRODUCTS_SUCCESS,
        payload: {params},
    }
}

export const allProductPaginate = params => {
    return {
        type: PAGINATE_PRODUCTS,
        payload: {params},
    }
}

export const allProductPaginateSuccess = result => {
    return {
        type: PAGINATE_PRODUCTS_SUCCESS,
        payload: {result},
    }
}

export const deleteProduct = (id, history) => {
  return {
    type: DELETE_PRODUCTS,
    payload: {id, history} ,
  }
}

export const deleteProductSuccess = message => {
    return {
        type: DELETE_PRODUCTS_SUCCESS,
        payload: message,
    }
}

export const apiPaginateProductError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

