import { takeEvery, put, call, takeLatest } from "redux-saga/effects"
import { NotificationManager } from "react-notifications";
import {
    CREATE_CUSTOMERS,
    EDIT_CUSTOMERS,
    FIND_BY_ID_CUSTOMERS
} from "./actionTypes"


import {
    createCustomerSuccess,
    editCustomerSuccess,
    findCustomerIdSuccess,
    apiCreateOrEditCustomerError
} from "./actions"


import {
    putEditCustomer,
    postCreateCustomer,
    getCustomerById,
 
} from "../../helpers/customers_helper"

function* findByIdCustomer({ payload: { id } }) {
    try {

        const response = yield call(getCustomerById, id)

        yield put(findCustomerIdSuccess(response))

    } catch (error) {
        yield put(apiCreateOrEditCustomerError(error))
    }
}

function* createCustomer({ payload: { params, existsFiles, history } }) {
    try {

        const response = yield call(postCreateCustomer, params)
        const { message, id, error } = response;

        yield put(createCustomerSuccess({ message, id, error, existsFiles }))


        NotificationManager.info(message, 'Aviso');

        if (!existsFiles) {
            history.goBack()
        }

    } catch (error) {
        yield put(apiCreateOrEditCustomerError(error))
    }
}

function* editCustomer({ payload: { params, existsFiles, files, history } }) {
    try {

        const response = yield call(putEditCustomer, params)

        yield put(editCustomerSuccess(response))

        const { message } = response;
        NotificationManager.info(message, 'Aviso');
        if (!existsFiles) {
            history.goBack()
        }


    } catch (error) {
        yield put(apiCreateOrEditCustomerError(error))
    }
}


function* createOrEditCompaniesSaga() {
    yield takeEvery(CREATE_CUSTOMERS, createCustomer)
    yield takeEvery(EDIT_CUSTOMERS, editCustomer)
    yield takeEvery(FIND_BY_ID_CUSTOMERS, findByIdCustomer)
}

export default createOrEditCompaniesSaga
