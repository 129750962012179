import React from 'react';
import MetaTags from 'react-meta-tags'
import {Card, CardBody, Col, Container, Row, Spinner} from "reactstrap";
import PropTypes from "prop-types";
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import Breadcrumbs from "../../../components/Breadcrumb";
import {GoogleApiWrapper, Map, Marker} from "google-maps-react";
import {connect} from "react-redux";
import {google_maps} from "../../../config";
import {searchAddress, reverseCoordinates, createCity} from "../../../store/actions"
import {AvField, AvForm} from "availity-reactstrap-validation"
import ButtonCustom from "../../../components/ButtonCustom";
import Autocomplete from "../../../components/Autocomplete";

const LoadingContainer = () => <div>Loading...</div>

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            status: 1,
            state_id: null,
            state: null,
            starting_track: null,
            final_track: null,
            isFindCep: false,
            ibge: null,
            woeid: null,
            siafi: null,
            gia: null
        }

        this.handleValidSubmit = this.handleValidSubmit.bind(this)
        this.handleSelectStatus = this.handleSelectStatus.bind(this)
        this.handleAutocomplete = this.handleAutocomplete.bind(this)
        this.handleFields = this.handleFields.bind(this)
       
    }

    componentDidMount() {


    }

    handleAutocomplete(_data) {

        if (_data) {
            const {value, name, code, data} = _data

            let newState = {}

            newState[name] = value
            newState['state_id'] = code

            console.log('newState ', newState)

            this.setState(newState)
        }
    }

    handleFields(event) {

        const {name, value} = event.target

        let tempState = {}
        tempState[name] = value

        this.setState(tempState)

    }

    handleSelectStatus(event) {

        const {value} = event.target

        this.setState({status: value})
    }

    handleValidSubmit(event, values) {
        this.props.createCity({
            name: values.name,
            siafi: values.siafi,
            woeid: values.woeid,
            gia: values.gia,
            ibge: values.ibge,
            ddd: values.ddd,
            code: values.code,
            state_id: this.state.state_id,
            status: this.state.status,
            lat: this.props.coordinates.lat,
            lng: this.props.coordinates.lng
        }, this.props.history)

    }

    render() {

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{this.props.t("City Register")}</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title={this.props.t("City")}
                                     breadcrumbItem={this.props.t("Register")}/>
                        <Row>
                            <Col sm={7}>
                                <Card>
                                    <CardBody>
                                        <Row className="align-items-md-center  mb-3">
                                            <Col className="inner-custom-pagination d-flex">
                                                <div className="d-inline">
                                                    <span className="h4 card-title">{this.props.t("Form")}</span>
                                                    <p className="card-title-desc mt-2">Preencha os campos abaixo, para
                                                        concluir o cadastro.</p>
                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <AvForm
                                                    className="form-horizontal"
                                                    onValidSubmit={this.handleValidSubmit}>

                                                    <Row>
                                                        <Col sm={8} className="mb-2">
                                                            <AvField
                                                                name="name"
                                                                label={this.props.t("Name")}
                                                                className="form-control"
                                                                placeholder="Digite o nome"
                                                                type="text"
                                                                onBlur={event => {
                                                                    const {name, value} = event.target
                                                                    if (!(Object.keys(value).length === 0)) {
                                                                        this.props.searchAddress(value)
                                                                    }
                                                                }}
                                                                required
                                                            />
                                                        </Col>
                                                        <Col sm={4} className="mb-2">
                                                            <AvField
                                                                value={this.state.status} onChange={this.handleSelectStatus}
                                                                type="select" name="select"
                                                                label={this.props.t("Status")}>
                                                                <option value="1">Ativo</option>
                                                                <option value="0">Inativo</option>
                                                            </AvField>
                                                        </Col>
                                                        <Col sm={3} className="mb-2">
                                                            <AvField
                                                                name="ibge"
                                                                label={this.props.t("IBGE")}
                                                                type="text"
                                                                value={this.state.ibge}
                                                                required
                                                            />
                                                        </Col>
                                                        <Col sm={3} className="mb-2">
                                                            <AvField
                                                                name="siafi"
                                                                label={this.props.t("SIAFI")}
                                                                type="text"
                                                                value={this.state.siafi}
                                                                required
                                                            />
                                                        </Col>
                                                        <Col sm={3} className="mb-2">
                                                            <AvField
                                                                name="gia"
                                                                value={this.state.gia}
                                                                label={this.props.t("GIA")}
                                                                type="text"
                                                                required
                                                            />
                                                        </Col>
                                                        <Col sm={3} >
                                                            <AvField
                                                                name="woeid"
                                                                value={this.state.woeid}
                                                                label={this.props.t("WOEID")}
                                                                type="text"
                                                                required
                                                            />
                                                        </Col>
                                                        <Col sm={2}>
                                                            <AvField
                                                                name="ddd"
                                                                label={this.props.t("DDD")}
                                                                type="number"
                                                                required
                                                            />
                                                        </Col>
                                                        <Col sm={2}>
                                                            <AvField
                                                                name="code"
                                                                label="cMun"
                                                                type="number"
                                                                required
                                                            />
                                                        </Col>
                                                      
                                                        <Col sm={6}>
                                                            <Autocomplete
                                                                onSourceSelect={this.handleAutocomplete}
                                                                inputProps={{
                                                                    id: 'state',
                                                                    label: this.props.t("State"),
                                                                    placeholder: this.props.t("State to search"),
                                                                    value: this.state.state,
                                                                    url: 'geo/search/states'
                                                                }}/>
                                                        </Col>
                                                        <Col sm={12} className="pt-3">
                                                            <ButtonCustom
                                                                isLoading={this.props.loading}
                                                                size="sm"
                                                                clazzContainer="text-center"
                                                                clazz="btn btn-info w-md"
                                                                message_loaging="">
                                                                <button
                                                                    className="btn btn-info w-md"
                                                                    type="submit">
                                                                    Salvar
                                                                </button>
                                                            </ButtonCustom>
                                                        </Col>
                                                    </Row>
                                                </AvForm>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm={5}>
                                <Card>
                                    <CardBody>
                                        <Row className="align-items-md-center  mb-3">
                                            <Col className="inner-custom-pagination d-flex">
                                                <div className="d-inline">
                                                    <span className="h4 card-title">{this.props.t("Map")}</span>
                                                    <p className="card-title-desc mt-3">Acompanhe a localidade no
                                                        mapa</p>
                                                </div>
                                                <div className="text-md-right ms-auto">
                                                    <button
                                                        onClick={() => {
                                                            this.props.history.goBack()
                                                        }}
                                                        className="btn btn-dark btn-sm text-light"
                                                    >
                                                        <i className="bx bx-left-arrow-circle"/>
                                                        <span> Voltar</span>
                                                    </button>
                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <div id="gmaps-makers" className="gmaps" style={{position: 'relative'}}>
                                                    {
                                                        this.props.coordinates.lat != 0 &&
                                                        <Map
                                                            google={this.props.google}
                                                            style={{width: '100%', height: '100%'}}
                                                            zoom={9}
                                                            loaded={this.props.loading_map}
                                                            center={this.props.coordinates}
                                                            initialCenter={this.props.coordinates}
                                                        >
                                                            <Marker
                                                                position={this.props.coordinates}
                                                            />
                                                        </Map>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>


        )
    }


}

Index.propTypes = {
    google: PropTypes.object,
    location: PropTypes.object,
    t: PropTypes.any,
    loading_map: PropTypes.any,
    coordinates: PropTypes.object,
    address: PropTypes.any,

    loading: PropTypes.any,
    error: PropTypes.any,
    message: PropTypes.any,

    searchAddress: PropTypes.func,
    reverseCoordinates: PropTypes.func,
    createCity: PropTypes.func,
}


const mapStateToProps = state => {
    const {loading_map, address, coordinates} = state.GeoLocation
    const {loading, error, message} = state.CreateOrEditCity
    return {loading_map, address, coordinates, loading, error, message}
}

export default withTranslation()(withRouter(connect(mapStateToProps, {reverseCoordinates, searchAddress, createCity})(
    GoogleApiWrapper({
        apiKey: google_maps.token,
        LoadingContainer: LoadingContainer,
        v: "3",
    })(Index)
)))

