import { takeEvery, put, call, takeLatest } from "redux-saga/effects"
import { NotificationManager } from "react-notifications";
import {
    CREATE_LOYALTY_TRANSACTION,
    EDIT_LOYALTY_TRANSACTION,
    FIND_BY_ID_LOYALTY_TRANSACTION
} from "./actionTypes"


import {
    createLoyaltyTransactionSuccess,
    editLoyaltyTransactionSuccess,
    findLoyaltyTransactionIdSuccess,
    apiCreateOrEditLoyaltyTransactionError
} from "./actions"


import {
    putEditLoyaltyTransaction,
    postCreateLoyaltyTransaction,
    getLoyaltyTransactionById,

} from "../../helpers/loyalty_transaction_helper"

function* findByIdLoyaltyTransaction({ payload: { id } }) {
    try {

        const response = yield call(getLoyaltyTransactionById, id)

        yield put(findLoyaltyTransactionIdSuccess(response))

    } catch (error) {
        yield put(apiCreateOrEditLoyaltyTransactionError(error))
    }
}

function* createLoyaltyTransaction({ payload: { params, existsFiles, history } }) {
    try {

        const response = yield call(postCreateLoyaltyTransaction, params)
        const { message, id, error } = response;

        yield put(createLoyaltyTransactionSuccess({ message, id, error, existsFiles }))


        NotificationManager.info(message, 'Aviso');

        if (!existsFiles) {
            history.goBack()
        }

    } catch (error) {
        yield put(apiCreateOrEditLoyaltyTransactionError(error))
    }
}

function* editLoyaltyTransaction({ payload: { params, existsFiles, files, history } }) {
    try {

        const response = yield call(putEditLoyaltyTransaction, params)

        yield put(editLoyaltyTransactionSuccess(response))

        const { message } = response;
        NotificationManager.info(message, 'Aviso');
        if (!existsFiles) {
            history.goBack()
        }


    } catch (error) {
        yield put(apiCreateOrEditLoyaltyTransactionError(error))
    }
}


function* createOrEditLoyaltyTransactionSaga() {
    yield takeEvery(CREATE_LOYALTY_TRANSACTION, createLoyaltyTransaction)
    yield takeEvery(EDIT_LOYALTY_TRANSACTION, editLoyaltyTransaction)
    yield takeEvery(FIND_BY_ID_LOYALTY_TRANSACTION, findByIdLoyaltyTransaction)
}

export default createOrEditLoyaltyTransactionSaga
