import {
    PAGINATE_PRODUCTS_PRICE_LIST,
    PAGINATE_PRODUCTS_PRICE_LIST_SUCCESS,
    FILTER_PAGINATE_PRODUCTS_PRICE_LIST,
    FILTER_PAGINATE_PRODUCTS_PRICE_LIST_SUCCESS,
    DELETE_PRODUCTS_PRICE_LIST_SUCCESS,
    DELETE_PRODUCTS_PRICE_LIST,
    API_ERROR,
} from "./actionTypes"


export const filtersAllProductsPriceListPaginate = params => {
    return {
        type: FILTER_PAGINATE_PRODUCTS_PRICE_LIST,
        payload: {params},
    }
}

export const filtersAllProductsPriceListPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_PRODUCTS_PRICE_LIST_SUCCESS,
        payload: {params},
    }
}

export const allProductsPriceListPaginate = params => {
    return {
        type: PAGINATE_PRODUCTS_PRICE_LIST,
        payload: {params},
    }
}

export const allProductsPriceListPaginateSuccess = result => {
    return {
        type: PAGINATE_PRODUCTS_PRICE_LIST_SUCCESS,
        payload: {result},
    }
}

export const deleteProductsPriceList = (id, history) => {
  return {
    type: DELETE_PRODUCTS_PRICE_LIST,
    payload: {id, history} ,
  }
}

export const deleteProductsPriceListSuccess = message => {
    return {
        type: DELETE_PRODUCTS_PRICE_LIST_SUCCESS,
        payload: message,
    }
}

export const apiPaginateProductsPriceListError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

