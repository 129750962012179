import React from "react"
import { Redirect } from "react-router-dom"
import './custom.css'

// Authentication related pages
import Login from "../pages/Authentication/Login"
//import Logout from "../pages/Authentication/Logout"
//import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Logout from "../pages/Authentication/Logout";

// Companies
import Companies from "../pages/Company/index";
import CreateCompany from "../pages/Company/create/index";
import EditCompany from "../pages/Company/edit/index";

import AboutUsCompany from "../pages/Company/options/aboutUs/index";
import SchedulersCompany from "../pages/Company/options/opening-hours/index";


// Countries
import Countries from "../pages/Country/index";
import CreateCountries from "../pages/Country/create/index";
import EditCountries from "../pages/Country/edit/index";

// States
import States from "../pages/State/index";
import CreateState from "../pages/State/create/index";
import EditState from "../pages/State/edit/index";

// Cities
import Cities from "../pages/Cities/index";
import CreateCity from "../pages/Cities/create/index";
import EditCity from "../pages/Cities/edit/index";

// Products
import Products from "../pages/Products/index";
import CreateProduct from "../pages/Products/create/index";
import EditProduct from "../pages/Products/edit/index";

// Tab Comissão
import TabComission from "../pages/TabComission/index";
import CreateTabComission from "../pages/TabComission/create/index";
import EditTabComission from "../pages/TabComission/edit/index";

// Tab Products
import TabPriceProducts from "../pages/TabPriceProducts/index";
import CreateTabPriceProducts from "../pages/TabPriceProducts/create/index";
import EditTabPriceProducts from "../pages/TabPriceProducts/edit/index";

// Tab Nivel Comission
import TabLevelComission from "../pages/levelComission/index";
import CreateTabLevelComission from "../pages/levelComission/create/index";
import EditTabLevelComission from "../pages/levelComission/edit/index";


// Settings
import IntroAPP from "../pages/Settings/Intro/index";
import CreateIntroAPP from "../pages/Settings/Intro/create/index";
import EditIntroAPP from "../pages/Settings/Intro/edit/index";

import EmailSettings from "../pages/Settings/Email/index";
import EmailTemplates from "../pages/Settings/Email/Layout/index";



const authProtectedRoutes = [

    { path: "/dashboard", component: Dashboard },

    { path: "/email-settings", component: EmailSettings },
    { path: "/templates-email", component: EmailTemplates },

    { path: "/intro-app", component: IntroAPP },
    { path: "/edit-intro/:entity_id", component: EditIntroAPP },
    { path: "/register-intro", component: CreateIntroAPP },

    { path: "/companies", component: Companies },
    { path: "/register-company", component: CreateCompany },
    { path: "/edit-company/:entity_id", component: EditCompany },

    { path: "/:entity_id/about-us", component: AboutUsCompany },
    { path: "/:entity_id/schedulers", component: SchedulersCompany },

    { path: "/countries", component: Countries },
    { path: "/register-country", component: CreateCountries },
    { path: "/edit-country/:entity_id", component: EditCountries },

    { path: "/states", component: States },
    { path: "/register-state", component: CreateState },
    { path: "/edit-state/:entity_id", component: EditState },

    { path: "/products", component: Products },
    { path: "/register-product", component: CreateProduct },
    { path: "/edit/:entity_id/product", component: EditProduct },

    { path: "/register-comission", component: CreateTabComission },
    { path: "/tab-comission", component: TabComission },
    { path: "/edit-tab/:entity_id/comission", component: EditTabComission },

    { path: "/register-tb-products", component: CreateTabPriceProducts },
    { path: "/tab-products", component: TabPriceProducts },
    { path: "/edit-tab/:entity_id/products", component: EditTabPriceProducts },


    { path: "/register-lv-comission", component: CreateTabLevelComission },
    { path: "/tab-lv-comission", component: TabLevelComission },
    { path: "/edit-tab/:entity_id/level", component: EditTabLevelComission },


    { path: "/cities", component: Cities },
    { path: "/register-city", component: CreateCity },
    { path: "/edit-city/:entity_id", component: EditCity },

    // this route should be at the end of all other routes
    { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
    { path: "/logout", component: Logout },
    { path: "/login", component: Login },
    { path: "/forget-password", component: ForgetPwd },
    // { path: "/register", component: Register },
]

export { authProtectedRoutes, publicRoutes }
