export const CREATE_PRODUCTS_PRICE_LIST = "CREATE_PRODUCTS_PRICE_LIST"
export const CREATE_PRODUCTS_PRICE_LIST_SUCCESS = "CREATE_PRODUCTS_PRICE_LIST_SUCCESS"

export const EDIT_PRODUCTS_PRICE_LIST = "EDIT_PRODUCTS_PRICE_LIST"
export const EDIT_PRODUCTS_PRICE_LIST_SUCCESS = "EDIT_PRODUCTS_PRICE_LIST_SUCCESS"

export const FIND_BY_ID_PRODUCTS_PRICE_LIST = "FIND_BY_ID_PRODUCTS_PRICE_LIST"
export const FIND_BY_ID_PRODUCTS_PRICE_LIST_SUCCESS = "FIND_BY_ID_PRODUCTS_PRICE_LIST_SUCCESS"

export const CREATE_OR_EDIT_PRODUCTS_PRICE_LIST_API_ERROR = "CREATE_OR_EDIT_PRODUCTS_PRICE_LIST_API_ERROR"
