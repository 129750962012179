import {
    CREATE_PRODUCTS_PRICE_LIST,
    CREATE_PRODUCTS_PRICE_LIST_SUCCESS,
    EDIT_PRODUCTS_PRICE_LIST,
    EDIT_PRODUCTS_PRICE_LIST_SUCCESS,
    FIND_BY_ID_PRODUCTS_PRICE_LIST,
    FIND_BY_ID_PRODUCTS_PRICE_LIST_SUCCESS,
    CREATE_OR_EDIT_PRODUCTS_PRICE_LIST_API_ERROR
} from "./actionTypes"


export const createProductsPriceList = (params,    history) => {
    return {
        type: CREATE_PRODUCTS_PRICE_LIST,
        payload: {params,  history},
    }
}

export const createProductsPriceListSuccess = params => {
    return {
        type: CREATE_PRODUCTS_PRICE_LIST_SUCCESS,
        payload: params,
    }
}

export const editProductsPriceList = (params, history) => {
    return {
        type: EDIT_PRODUCTS_PRICE_LIST,
        payload: {params, history},
    }
}

export const editProductsPriceListSuccess = result => {
    return {
        type: EDIT_PRODUCTS_PRICE_LIST_SUCCESS,
        payload: {result},
    }
}

export const findByIdProductsPriceList = id => {
    return {
        type: FIND_BY_ID_PRODUCTS_PRICE_LIST,
        payload: {id},
    }
}

export const findProductsPriceListIdSuccess = result => {
    return {
        type: FIND_BY_ID_PRODUCTS_PRICE_LIST_SUCCESS,
        payload: result,
    }
}

export const apiCreateOrEditProductsPriceListError = error => {
    return {
        type: CREATE_OR_EDIT_PRODUCTS_PRICE_LIST_API_ERROR,
        payload: error,
    }
}

