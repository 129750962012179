export const PAGINATE_LOYALTY_TRANSACTION = "PAGINATE_LOYALTY_TRANSACTION"
export const PAGINATE_LOYALTY_TRANSACTION_SUCCESS = "PAGINATE_LOYALTY_TRANSACTION_SUCCESS"

export const FILTER_PAGINATE_LOYALTY_TRANSACTION = "FILTER_PAGINATE_LOYALTY_TRANSACTION"
export const FILTER_PAGINATE_LOYALTY_TRANSACTION_SUCCESS = "FILTER_PAGINATE_LOYALTY_TRANSACTION_SUCCESS"

export const DELETE_LOYALTY_TRANSACTION = "DELETE_LOYALTY_TRANSACTION"
export const DELETE_LOYALTY_TRANSACTION_SUCCESS = "DELETE_LOYALTY_TRANSACTION_SUCCESS"

export const API_ERROR = "PAGINATE_LOYALTY_TRANSACTION_API_ERROR"
