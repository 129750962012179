import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_CUSTOMER,
    FILTER_PAGINATE_CUSTOMER,
    DELETE_CUSTOMER,
} from "./actionTypes"

import {
    allCustomerPaginateSuccess,
    filtersAllCustomerPaginateSuccess,
    deleteCustomerSuccess,
    apiPaginateCustomerError
} from "./actions"


import {
    getCustomersPaginate,
    delCustomer
} from "../../../helpers/customers_helper"
import {NotificationManager} from "react-notifications";

function* deleteCustomer({payload: {id, history}}) {
    try {

        const response = yield call(delCustomer, id)

        yield put(deleteCustomerSuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateCustomerError(error))
    }
}

function* paginateCustomer({payload: {params}}) {
    try {

        const response = yield call(getCustomersPaginate, params)

        yield put(allCustomerPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateCustomerError(error))
    }
}



function* CustomerSaga() {
    yield takeEvery(PAGINATE_CUSTOMER, paginateCustomer)
    yield takeEvery(DELETE_CUSTOMER, deleteCustomer)
}

export default CustomerSaga
