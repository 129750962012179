import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import { NotificationManager } from "react-notifications";


const getLevelComissionListById = data => get(url.GET_LEVEL_COMISSION_LIST_BY_ID.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delLevelComissionList = data => del(url.DELETE_LEVEL_COMISSION_LIST.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateLevelComissionList = data => post(url.POST_CREATE_LEVEL_COMISSION_LIST, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditLevelComissionList = data => put(url.PUT_EDIT_LEVEL_COMISSION_LIST, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getLevelComissionListPaginate = ({ page, limit }) => get(url.GET_LEVEL_COMISSION_LIST_PAGINATE, { params: { page, limit } })
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

export {
    getLevelComissionListPaginate,
    putEditLevelComissionList,
    postCreateLevelComissionList,
    delLevelComissionList,
    getLevelComissionListById,
    
}
