import React, { useEffect } from "react";
import $ from "jquery";
import "jquery-ui-bundle/jquery-ui";
import "jquery-ui-bundle/jquery-ui.css";
import { Form } from "react-bootstrap";
import './index.css';

import { del, get, post, put } from "../../helpers/api_helper"

import { NotificationManager } from "react-notifications";

/**
 * @param { id, label, value } inputProps
 * @param { função para setar o valor } onSourceSelect
 * @param nameFieldSelect
 * @param limit
 */

function Autocomplete({ inputProps, onSourceSelect, nameFieldSelect, limit }) {

	var _userFocusOutData = ""

	useEffect(() => {

		try {

			$("#" + inputProps.id).autocomplete({
				delay: 2,
				autoFocus: true,
				autoFill: true,
				scrollHeight: 220,
				minLength: 2,
				source: function (request, response) {

					const dados = Object.entries(
						Object.assign({ query: request.term }, inputProps.data)
					).map(item => item.join('=')).join('&')

					try {

						get(inputProps.url + '?' + dados + (inputProps.param_id ? '&param_id=' + inputProps.param_id : '') + '&limit=' + (limit ? limit : 10))
							.then(resp => {

								const { data } = resp;
								console.log('results ', data)

								_userFocusOutData = data

								response(data.map(item => {
									return {
										label: item.text,
										value: item.text,
										name: inputProps.id,
										code: item.id,
										data: item
									}
								}))

								$(`[name='${inputProps.id}']`).removeClass("ui-autocomplete-loading")

							})
							.catch(error => {

								if (error.code === 404) {
									NotificationManager.warning('Não existem registros para serem exibidos', 'Ops!', 3000);
								}

								if (error.code === 401) {
									NotificationManager.warning('Não Autenticado, faça o login novamente', 'Ops!', 3000);
								}

								$(`[name='${inputProps.id}']`).removeClass("ui-autocomplete-loading")

							}).then(response => {
							})

					} catch (error) {
						console.log('error', error)
					}

				},
				select: function (event, ui) {
					onSourceSelect(ui.item, nameFieldSelect)
				},
				change: function (event, ui) {

					if (!ui.item) {

						onSourceSelect(ui.item, nameFieldSelect)

					}

				},
				open: function () {
					$(this).removeClass("ui-corner-all").addClass("ui-corner-top");
				},
				close: function () {
					$(this).removeClass("ui-corner-top").addClass("ui-corner-all");
				}

			})

		} catch (error) {
			console.error(error)
		}

	}, [])


	return (
		<Form.Group>
			<label htmlFor={inputProps.id} className="font-label">{inputProps.label}</label>
			<Form.Control
				type="text"
				className="form-control"
				placeholder={inputProps.placeholder}
				id={inputProps.id}
				name={inputProps.id}
				onChange={e => onSourceSelect({ value: e.target.value, name: inputProps.id, code: '' }, nameFieldSelect)}
				value={inputProps.value} />
		</Form.Group>
	)

}

export default Autocomplete
