import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import { NotificationManager } from "react-notifications";


const getProductsById = data => get(url.GET_PRODUCTS_BY_ID.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delProducts = data => del(url.DELETE_PRODUCTS.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateProducts = data => post(url.POST_CREATE_PRODUCTS, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditProducts = data => put(url.PUT_EDIT_PRODUCTS, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getProductsPaginate = ({ page, limit }) => get(url.GET_PRODUCTS_PAGINATE, { params: { page, limit } })
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postUploadProducts = data => post(url.POST_UPLOAD_PRODUCTS, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })


export {
    getProductsPaginate,
    putEditProducts,
    postCreateProducts,
    delProducts,
    getProductsById,
    postUploadProducts

}
