import {
    PAGINATE_CUSTOMER,
    PAGINATE_CUSTOMER_SUCCESS,
    FILTER_PAGINATE_CUSTOMER,
    FILTER_PAGINATE_CUSTOMER_SUCCESS,
    DELETE_CUSTOMER_SUCCESS,
    DELETE_CUSTOMER,
    API_ERROR,
} from "./actionTypes"


export const filtersAllCustomerPaginate = params => {
    return {
        type: FILTER_PAGINATE_CUSTOMER,
        payload: {params},
    }
}

export const filtersAllCustomerPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_CUSTOMER_SUCCESS,
        payload: {params},
    }
}

export const allCustomerPaginate = params => {
    return {
        type: PAGINATE_CUSTOMER,
        payload: {params},
    }
}

export const allCustomerPaginateSuccess = result => {
    return {
        type: PAGINATE_CUSTOMER_SUCCESS,
        payload: {result},
    }
}

export const deleteCustomer = (id, history) => {
  return {
    type: DELETE_CUSTOMER,
    payload: {id, history} ,
  }
}

export const deleteCustomerSuccess = message => {
    return {
        type: DELETE_CUSTOMER_SUCCESS,
        payload: message,
    }
}

export const apiPaginateCustomerError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

