import React from 'react';
import MetaTags from 'react-meta-tags'
import { Card, CardBody, CardHeader, CardTitle, Col, Container, Row, Spinner } from "reactstrap";
import Breadcrumbs from "../../../../components/Breadcrumb";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import { AvField, AvForm } from "availity-reactstrap-validation";
import ButtonCustom from "../../../../components/ButtonCustom";
import GenericsServiceAPI from "../../../../helpers/GenericsServicesAPI";
import { NotificationManager } from "react-notifications";
import { findAboutUSCompany } from "../../../../store/companies/actions";


class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

            path_file_before_front_cover: '',
            path_file_before_image: '',
            front_cover: '',

            file_front_cover: null,
            existsFile_front_cover: false,

            image: '',

            file_image: null,
            existsFile_image: false,

        }
        this.handleDrop = this.handleDrop.bind(this);
        this.handleDropImage = this.handleDropImage.bind(this);
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
        this.sendUpload = this.sendUpload.bind(this);
    }

    componentDidMount() {
        const { entity_id } = this.props.match.params
        this.props.findAboutUSCompany(entity_id)


    }

    sendUpload(formData, url) {

        GenericsServiceAPI.upload(formData, url)
            .then(response => {
                const { message } = response;
                const { type_dir_file, front_cover, image } = response.data;

                switch (type_dir_file) {

                    case 'front_cover':
                        const { url_front_cover } = response.data;
                        // this.props.url_front_cover = url_front_cover;
                        this.setState({ front_cover: url_front_cover, path_file_before_front_cover: front_cover });
                        break;
                    case 'image':
                        const { url_image } = response.data;
                        //this.props.url_image = url_image;
                        this.setState({ image: url_image, path_file_before_image: image });
                        break;

                }

                //   this.props.existsFiles = false
                // this.props.loading = false
                NotificationManager.info(message, 'Aviso!');
                //history.goBack()

            })
            .catch(err => {
                console.log('error upload ', err)
                //  this.props.loading = false
                // NotificationManager.warning(err, 'Ops!', 3000);
            })
            .then(response => {

            });
    }

    handleDrop(e) {

        let reader = new FileReader();
        let file = e[0];

        reader.onloadend = () => {
            this.setState({
                file_front_cover: file,
                existsFile_front_cover: true,
                front_cover: reader.result
            });
        }

        reader.readAsDataURL(file)
        const { entity_id } = this.props.match.params
        var data = new FormData();
        data.append('id', entity_id);
        data.append('type_dir_file', 'front_cover');
        data.append('files', file);
        data.append('path_file_before', this.state.path_file_before_front_cover !== '' ? this.state.path_file_before_front_cover : this.props.front_cover);
        this.sendUpload(data, 'upload/companies')


    }

    handleDropImage(e) {

        let reader = new FileReader();
        let file = e[0];

        reader.onloadend = () => {
            this.setState({
                file_image: file,
                existsFile_image: true,
                image: reader.result
            });
        }

        reader.readAsDataURL(file)
        const { entity_id } = this.props.match.params
        var data = new FormData();
        data.append('id', entity_id);
        data.append('type_dir_file', 'image');
        data.append('files', file);
        data.append('path_file_before', this.state.path_file_before_image !== '' ? this.state.path_file_before_image : this.props.image);
        this.sendUpload(data, 'upload/companies')
    }

    handleValidSubmit(event, values) {
        /* this.props.editCategories({
             id: this.props.id,
             name: values.name,
             description: values.description,
             model_menu_id: this.state.model_menu_id ? this.state.model_menu_id : this.props.model_menu_id,
             company_id: this.state.company_id ? this.state.company_id : this.props.company_id,
             status: this.state.status ? this.state.status : this.props.status,
             availability: this.state.availability ? this.state.availability : this.props.availability,
         }, this.props.history)*/

    }

    render() {

        const { url_image, url_front_cover, loading_form } = this.props;


        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Sobre</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title="Sobre" breadcrumbItem={this.props.t("Update")} />
                        <Row>
                            {
                                this.props.loading_form
                                    ?
                                    <Col className="col-12">
                                        <Card>
                                            <CardBody>
                                                <Row className="align-items-md-center  mb-3">
                                                    <Col className="inner-custom-pagination d-flex">
                                                        <div className="text-center" style={{
                                                            width: '100%',
                                                            position: 'absolute',
                                                            paddingBottom: 20
                                                        }}>
                                                            <Spinner size="sm" animation="border"
                                                                style={{ marginBottom: 20 }}
                                                                variant="success" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>

                                    </Col>
                                    :
                                    <>
                                        <Col sm={12}>
                                            <Card>
                                                <CardBody>
                                                    <Row className="align-items-md-center  mb-3">
                                                        <Col className="inner-custom-pagination d-flex">
                                                            <div className="d-inline">
                                                                <span
                                                                    className="h4 card-title">{this.props.t("Front Cover")}</span>
                                                                <p className="card-title-desc mt-2">Informe a imagem de
                                                                    capa
                                                                    (1024x300)</p>
                                                            </div>
                                                            <div className="text-md-right ms-auto">
                                                                <a
                                                                    onClick={() => {
                                                                        this.props.history.goBack()
                                                                    }}
                                                                    className="btn btn-dark btn-sm text-light"
                                                                >
                                                                    <span> Voltar</span>
                                                                </a>
                                                            </div>
                                                        </Col>
                                                        <Col sm={12}>
                                                            <Dropzone onDrop={this.handleDrop}>

                                                                {({ getRootProps, getInputProps }) => (

                                                                    <div {...getRootProps({ className: "dropzone" })}>
                                                                        <input {...getInputProps()} />
                                                                        {url_front_cover !== ''
                                                                            ?
                                                                            <img
                                                                                className="thumbnail img-responsive img-front"
                                                                                src={url_front_cover ? this.state.front_cover !== '' ? this.state.front_cover : url_front_cover : url_front_cover} />
                                                                            : <p style={{ marginTop: '10%' }}
                                                                                className="text-center">Arraste e
                                                                                solte ou
                                                                                clique
                                                                                para adicionar a imagem</p>
                                                                        }

                                                                    </div>

                                                                )}

                                                            </Dropzone>
                                                        </Col>
                                                    </Row>

                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col sm={6}>
                                            <Card>
                                                <CardBody>
                                                    <Row className="align-items-md-center  mb-3">
                                                        <Col className="inner-custom-pagination d-flex">
                                                            <div className="d-inline">
                                                                <span
                                                                    className="h4 card-title">{this.props.t("Logo")}</span>
                                                                <p className="card-title-desc mt-2">Informe a logo marca
                                                                    (512x512)</p>
                                                            </div>
                                                        </Col>
                                                        <Col sm={12}>
                                                            <Dropzone onDrop={this.handleDropImage}>

                                                                {({ getRootProps, getInputProps }) => (

                                                                    <div {...getRootProps({ className: "dropzone" })}>
                                                                        <input {...getInputProps()} />
                                                                        {url_image !== ''
                                                                            ?
                                                                            <img
                                                                                className="thumbnail img-responsive img-front-logo"
                                                                                src={url_image ? this.state.image !== '' ? this.state.image : url_image : this.state.image} />
                                                                            : <p style={{ marginTop: '10%' }}
                                                                                className="text-center">Arraste e
                                                                                solte ou
                                                                                clique
                                                                                para adicionar a imagem</p>
                                                                        }

                                                                    </div>

                                                                )}

                                                            </Dropzone>
                                                        </Col>
                                                    </Row>

                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col sm={6}>
                                            <Card>
                                                <CardBody>
                                                    <Row className="align-items-md-center  mb-3">
                                                        <Col className="inner-custom-pagination d-flex">
                                                            <div className="d-inline">
                                                                <span
                                                                    className="h4 card-title">{this.props.t("Description")}</span>
                                                                <p className="card-title-desc mt-2">Fale um pouco sobre
                                                                    o
                                                                    estabelecimento</p>
                                                            </div>
                                                        </Col>
                                                        <Col sm={12}>
                                                            <AvForm
                                                                className="form-horizontal"
                                                                onValidSubmit={this.handleValidSubmit}>
                                                                <Row>
                                                                    <Col sm={12}>
                                                                        <textarea
                                                                            name="description"
                                                                            className="form-control"
                                                                            value={this.props.description}
                                                                            placeholder="Digite uma descrição"
                                                                            rows={8}
                                                                            required
                                                                        />
                                                                    </Col>
                                                                    <Col sm={12} className="pt-3">
                                                                        <ButtonCustom
                                                                            isLoading={false}
                                                                            size="sm"
                                                                            clazzContainer="text-center"
                                                                            clazz="btn btn-info w-md"
                                                                            message_loaging="">
                                                                            <button
                                                                                className="btn btn-info w-md"
                                                                                type="submit">
                                                                                Salvar
                                                                            </button>
                                                                        </ButtonCustom>
                                                                    </Col>
                                                                </Row>
                                                            </AvForm>
                                                        </Col>
                                                    </Row>

                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </>
                            }
                        </Row>

                    </Container>

                </div>
            </React.Fragment>


        )
    }


}

Index.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    type: PropTypes.string,
    loading: PropTypes.any,
    loading_form: PropTypes.any,


    id: PropTypes.any,
    image: PropTypes.any,
    front_cover: PropTypes.any,
    url_image: PropTypes.any,
    url_front_cover: PropTypes.any,
    type_dir_file: PropTypes.any,

}
const mapStateToProps = state => {
    const {
        loading,
        loading_form,


        id,
        image,
        front_cover,
        url_image,
        url_front_cover,
        type_dir_file
    } = state.CreateOrEditCompanies
    return {
        loading,
        loading_form,
        id,
        image,
        front_cover,
        url_image,
        url_front_cover,
        type_dir_file
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
    findAboutUSCompany

})(
    (Index)
)))
