import { takeEvery, put, call, takeLatest } from "redux-saga/effects"
import { NotificationManager } from "react-notifications";
import {
    CREATE_LEVEL_COMISSION_LIST,
    EDIT_LEVEL_COMISSION_LIST,
    FIND_BY_ID_LEVEL_COMISSION_LIST
} from "./actionTypes"


import {
    createLevelComissionListSuccess,
    editLevelComissionListSuccess,
    findLevelComissionListIdSuccess,
    apiCreateOrEditLevelComissionListError
} from "./actions"


import {
    putEditLevelComissionList,
    postCreateLevelComissionList,
    getLevelComissionListById,

} from "../../helpers/level_comission_list_helper"

function* findByIdLevelComissionList({ payload: { id } }) {
    try {

        const response = yield call(getLevelComissionListById, id)

        yield put(findLevelComissionListIdSuccess(response))

    } catch (error) {
        yield put(apiCreateOrEditLevelComissionListError(error))
    }
}

function* createLevelComissionList({ payload: { params,  history } }) {
    try {

        const response = yield call(postCreateLevelComissionList, params)
        const { message, id, error } = response;

        yield put(createLevelComissionListSuccess({ message, id, error, existsFiles }))


        NotificationManager.info(message, 'Aviso');


        history.goBack()


    } catch (error) {
        yield put(apiCreateOrEditLevelComissionListError(error))
    }
}

function* editLevelComissionList({ payload: { params, existsFiles, files, history } }) {
    try {

        const response = yield call(putEditLevelComissionList, params)

        yield put(editLevelComissionListSuccess(response))

        const { message } = response;
        NotificationManager.info(message, 'Aviso');
        if (!existsFiles) {
            history.goBack()
        }


    } catch (error) {
        yield put(apiCreateOrEditLevelComissionListError(error))
    }
}


function* createOrEditLevelComissionListSaga() {
    yield takeEvery(CREATE_LEVEL_COMISSION_LIST, createLevelComissionList)
    yield takeEvery(EDIT_LEVEL_COMISSION_LIST, editLevelComissionList)
    yield takeEvery(FIND_BY_ID_LEVEL_COMISSION_LIST, findByIdLevelComissionList)
}

export default createOrEditLevelComissionListSaga
