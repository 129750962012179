import {
    CREATE_PRICE_COMISSION_LIST,
    CREATE_PRICE_COMISSION_LIST_SUCCESS,
    EDIT_PRICE_COMISSION_LIST,
    EDIT_PRICE_COMISSION_LIST_SUCCESS,
    FIND_BY_ID_PRICE_COMISSION_LIST,
    FIND_BY_ID_PRICE_COMISSION_LIST_SUCCESS,
    CREATE_OR_EDIT_PRICE_COMISSION_LIST_API_ERROR
} from "./actionTypes"


export const createPriceComissionList = (params, existsFiles,   history) => {
    return {
        type: CREATE_PRICE_COMISSION_LIST,
        payload: {params,existsFiles,  history},
    }
}

export const createPriceComissionListSuccess = params => {
    return {
        type: CREATE_PRICE_COMISSION_LIST_SUCCESS,
        payload: params,
    }
}

export const editPriceComissionList = (params, history) => {
    return {
        type: EDIT_PRICE_COMISSION_LIST,
        payload: {params, history},
    }
}

export const editPriceComissionListSuccess = result => {
    return {
        type: EDIT_PRICE_COMISSION_LIST_SUCCESS,
        payload: {result},
    }
}

export const findByIdPriceComissionList = id => {
    return {
        type: FIND_BY_ID_PRICE_COMISSION_LIST,
        payload: {id},
    }
}

export const findPriceComissionListIdSuccess = result => {
    return {
        type: FIND_BY_ID_PRICE_COMISSION_LIST_SUCCESS,
        payload: result,
    }
}

export const apiCreateOrEditPriceComissionListError = error => {
    return {
        type: CREATE_OR_EDIT_PRICE_COMISSION_LIST_API_ERROR,
        payload: error,
    }
}

