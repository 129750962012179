export const CREATE_CUSTOMERS = "CREATE_CUSTOMERS"
export const CREATE_CUSTOMERS_SUCCESS = "CREATE_CUSTOMERS_SUCCESS"

export const EDIT_CUSTOMERS = "EDIT_CUSTOMERS"
export const EDIT_CUSTOMERS_SUCCESS = "EDIT_CUSTOMERS_SUCCESS"

export const FIND_BY_ID_CUSTOMERS = "FIND_BY_ID_CUSTOMERS"
export const FIND_BY_ID_CUSTOMERS_SUCCESS = "FIND_BY_ID_CUSTOMERS_SUCCESS"

export const CREATE_OR_EDIT_CUSTOMERS_API_ERROR = "CREATE_OR_EDIT_CUSTOMERS_API_ERROR"
