import { all, fork } from "redux-saga/effects"

//public
import LayoutSaga from "./layout/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"

//private
import CompaniesSaga from "./companies/table/saga"
import CreateOrEditCompaniesSaga from "./companies/saga"

import CompaniesSchedulerSaga from "./companies/options/scheduler-company/table/saga.js"
import CreateOrEditCompaniesSchedulerSaga from "./companies/options/scheduler-company/saga"

//Customer
import CreateOrEditCustomerSaga from "./customers/saga"
import CustomersSaga from "./customers/table/saga"

//Product
import CreateOrEditProductSaga from "./products/saga"
import ProductsSaga from "./products/table/saga"

// Price Commision List
import PriceListComissionSaga from "./priceComissionList/table/saga"
import CreateOrEditPriceListComissionSaga from "./priceComissionList/saga"

// Products price list
import ProductsPriceListSaga from "./productsPriceList/table/saga"
import CreateOrEditProductsPriceListSaga from "./productsPriceList/saga"

// Level Commision List
import LevelListComissionSaga from "./levelComissionList/table/saga"
import CreateOrEditLevelListComissionSaga from "./levelComissionList/saga"

import LoyaltyTransactionSaga from "./loyaltyTransaction/table/saga"
import CreateOrEditLoyaltyTransactionSaga from "./loyaltyTransaction/saga"

//COUNTRY
import CountrySaga from "./country/table/saga"
import CreateOrEditCountrySaga from "./country/saga"

// STATE
import StateSaga from "./state/table/saga"
import CreateOrEditStateSaga from "./state/saga"

// CITY
import CitySaga from "./city/table/saga"
import CreateOrEditCitySaga from "./city/saga"

import LocationSaga from "./search/location/saga"
import SearchCompaniesSaga from "./search/companies/saga"

// SETTING
import SettingsSaga from "./settings/table/saga"
import CreateOrEditSettingsSaga from "./settings/saga"

export default function* rootSaga() {
    yield all([

        //public
        fork(AuthSaga),
        fork(ForgetSaga),
        fork(LayoutSaga),

        //private
        fork(CountrySaga),
        fork(CreateOrEditCountrySaga),
        fork(StateSaga),
        fork(CreateOrEditStateSaga),
        fork(CitySaga),
        fork(CreateOrEditCitySaga),
        fork(LocationSaga),
        fork(SearchCompaniesSaga),
        fork(CompaniesSaga),
        fork(CreateOrEditCompaniesSaga),
        fork(CompaniesSchedulerSaga),
        fork(CreateOrEditCompaniesSchedulerSaga),

        fork(SettingsSaga),
        fork(CreateOrEditSettingsSaga),

        fork(CustomersSaga),
        fork(CreateOrEditCustomerSaga),

        fork(ProductsSaga),
        fork(CreateOrEditProductSaga),

        fork(PriceListComissionSaga),
        fork(CreateOrEditPriceListComissionSaga),

        fork(LevelListComissionSaga),
        fork(CreateOrEditLevelListComissionSaga),
        fork(LoyaltyTransactionSaga),
        fork(CreateOrEditLoyaltyTransactionSaga),

        fork(ProductsPriceListSaga),
        fork(CreateOrEditProductsPriceListSaga),
    ])
}
