import React from 'react';
import MetaTags from 'react-meta-tags'
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumbs from "../../../components/Breadcrumb";
import { connect } from "react-redux";
import { createLevelComissionList } from "../../../store/actions"
import { AvField, AvForm } from "availity-reactstrap-validation"
import ButtonCustom from "../../../components/ButtonCustom";
import { Form } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import Autocomplete from 'components/Autocomplete'



class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            status: 1,
            type: 1,
            level: 1,
            price: null,
            percentual: null,
        }
        this.handleAutocomplete = this.handleAutocomplete.bind(this)
        this.handleValidSubmit = this.handleValidSubmit.bind(this)
        this.handleFields = this.handleFields.bind(this)
        this.handleRadioButtom = this.handleRadioButtom.bind(this)
      
    }

    componentDidMount() {


    }
    handleRadioButtom(event) {
        const { id, name, value } = event.target
        let newobj = {}
        newobj[name] = this.state[name] === 1 ? 0 : 1
        this.setState(newobj)

    }

    async handleAutocomplete(_data, name_entity) {

        if (_data) {
            const { value, name, code, data } = _data
            let newState = {}

            newState[name] = value
            newState[name + '_id'] = code

            //   console.log('newState ', newState)
            //   console.log('name_entity ', name_entity)
            //console.log('nadata ', data)

            this.setState(newState)

        }
    }


    handleFields(event) {

        const { value } = event.target

        this.setState({ status: value })
    }



    handleValidSubmit(event, values) {

        var data = new FormData();

        data.append('percentual', this.state.percentual);
        data.append('p_com_list_id', this.state.price_comission_list_id);
        data.append('status', this.state.status);
        data.append('price', this.state.price);
        data.append('type', this.state.type);
        data.append('level', this.state.level);

        this.props.createLevelComissionList(data, this.props.history)

    }

    render() {


        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Cadastro de Tab. Niveis Comissão</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title="Nivel Comissão"
                            breadcrumbItem="Cadastro" />
                        <Row>
                            <Col sm={7}>
                                <Card>
                                    <CardBody>
                                        <Row className="align-items-md-center  mb-3">
                                            <Col className="inner-custom-pagination d-flex">
                                                <div className="d-inline">
                                                    <span className="h4 card-title">Formulário</span>
                                                    <p className="card-title-desc mt-2">Preencha os campos abaixo, para
                                                        concluir o cadastro.</p>
                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <AvForm
                                                    className="form-horizontal"
                                                    onValidSubmit={this.handleValidSubmit}>

                                                    <Row>
                                                        <Col sm={8} className="mb-2">
                                                            <Autocomplete
                                                                onSourceSelect={(d, e) => this.handleAutocomplete(d, e)}
                                                                nameFieldSelect='p_com_list_id'
                                                                limit={10}
                                                                inputProps={{
                                                                    id: 'price_comission_list',
                                                                    name: 'price_comission_list',
                                                                    label: "Tabela Comissão",
                                                                    placeholder: "Pesquisar comissão..",
                                                                    param_id: '',
                                                                    value: this.state.p_com_list_id ? this.state.p_com_list_id : this.props.p_com_list_id,
                                                                    url: 'search/tab-comission'
                                                                }} />

                                                        </Col>
                                                        <Col sm={4}>
                                                            <AvField
                                                                value={this.state.status} onChange={this.handleFields}
                                                                type="select" name="select"
                                                                label={this.props.t("Status")}>
                                                                <option value="1">Ativo</option>
                                                                <option value="0">Inativo</option>
                                                            </AvField>
                                                        </Col>
                                                        <Col sm={2} className="mb-2">
                                                            <div className="form-group">
                                                                <label htmlFor=""
                                                                    className="control-label">Nivel </label>
                                                                <Form.Control
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="0"
                                                                    name="level"
                                                                    onChange={(event) => {

                                                                        const { name, value } = event.target
                                                                        this.setState({ level: value })

                                                                    }}
                                                                    value={this.state.level ? this.state.level : this.props.level} />
                                                            </div>

                                                        </Col>

                                                        <Col sm={3} className="mb-2">
                                                            <div className="form-group">
                                                                <label htmlFor=""
                                                                    className="control-label">Preço </label>
                                                                <Form.Control
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="00,00"
                                                                    name="price"
                                                                    as={InputMask}
                                                                    mask={"99,99"}
                                                                    onChange={(event) => {

                                                                        const { name, value } = event.target
                                                                        this.setState({ price: value })

                                                                    }}
                                                                    value={this.state.price ? this.state.price : this.props.price} />
                                                            </div>

                                                        </Col>
                                                        <Col sm={2} className="mb-2">
                                                            <div className="form-group">
                                                                <label htmlFor=""
                                                                    className="control-label">Percentual </label>
                                                                <Form.Control
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="0"
                                                                    name="percentual"
                                                                    onChange={(event) => {

                                                                        const { name, value } = event.target
                                                                        this.setState({ percentual: value })

                                                                    }}
                                                                    value={this.state.percentual ? this.state.percentual : this.props.percentual} />
                                                            </div>

                                                        </Col>
                                                        <Col sm={6} className="pt-3">
                                                            <div className="mt-4" onChange={this.handleRadioButtom}>
                                                                <h5 className="font-size-14 mb-4">Tipo de cálculo</h5>
                                                                <div className="form-check ">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="type"
                                                                        id="type"
                                                                        value="1"
                                                                        defaultChecked

                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="type"
                                                                    >
                                                                        Percentual Agregado
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="type"
                                                                        id="type"
                                                                        value="2"

                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="type"
                                                                    >
                                                                        Valor Fixo
                                                                    </label>
                                                                </div>
                                                            </div>

                                                        </Col>
                                                        <Col sm={12} className="pt-3">
                                                            <ButtonCustom
                                                                isLoading={this.props.loading}
                                                                size="sm"
                                                                clazzContainer="text-center"
                                                                clazz="btn btn-info w-md"
                                                                message_loaging="">
                                                                <button
                                                                    className="btn btn-info w-md"
                                                                    type="submit">
                                                                    Salvar
                                                                </button>
                                                            </ButtonCustom>
                                                        </Col>
                                                    </Row>
                                                </AvForm>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm={5}>
                                <Card>
                                    <CardBody>
                                        <Row className="align-items-md-center  mb-3">
                                            <Col className="inner-custom-pagination d-flex">
                                                <div className="d-inline">
                                                    <span className="h4 card-title">Leia-me</span>
                                                </div>
                                                <div className="text-md-right ms-auto">
                                                    <button
                                                        onClick={() => {
                                                            this.props.history.goBack()
                                                        }}
                                                        className="btn btn-dark btn-sm text-light"
                                                    >
                                                        <i className="bx bx-left-arrow-circle" />
                                                        <span> Voltar</span>
                                                    </button>
                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <div className='mt-3'>
                                                    Segue abaixo a instrução para regra do nivel comissão.

                                                    <li className='mt-3'><small>Tabela Comissão: Indica para o sistema em qual regra de comissão o produto/serviço pertence.</small></li>
                                                    <li className='mt-2'><small>Nivel: Indicativo no nivel que a regra pertence, limitado pelo nivel máximo da tab. de comissão.</small></li>
                                                    <li className='mt-2'><small>Tipo: Indica como a tabela do nivel, deverá trabalhar, qual a logica de calculo aplicada.</small></li>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>


        )
    }


}

Index.propTypes = {
    t: PropTypes.any,
    loading: PropTypes.any,
    error: PropTypes.any,
    message: PropTypes.any,
    url_image: PropTypes.any,
    createLevelComissionList: PropTypes.func,
}


const mapStateToProps = state => {
    const { loading, error, message } = state.CreateOrEditProduct
    return { loading, error, message }
}

export default withTranslation()(withRouter(
    connect(mapStateToProps, {
        createLevelComissionList

    })(Index)
))


