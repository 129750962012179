import React from 'react';
import MetaTags from 'react-meta-tags'
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumbs from "../../../components/Breadcrumb";
import { connect } from "react-redux";
import { createPriceComissionList } from "../../../store/actions"
import { AvField, AvForm } from "availity-reactstrap-validation"
import ButtonCustom from "../../../components/ButtonCustom";
import { Form } from 'react-bootstrap';
import InputMask from 'react-input-mask';

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            status: 1,
            price_type: 1,
            level_max: 5,
            comission_type: 1,
        }

        this.handleValidSubmit = this.handleValidSubmit.bind(this)
        this.handleFields = this.handleFields.bind(this)
        this.handleRadioButtom = this.handleRadioButtom.bind(this)
    }

    componentDidMount() {


    }
    handleRadioButtom(event) {
        const { id, name, value } = event.target
        let newobj = {}
        newobj[name] = this.state[name] === 1 ? 0 : 1
        this.setState(newobj)

    }

    handleFields(event) {

        const { value } = event.target

        this.setState({ status: value })
    }



    handleValidSubmit(event, values) {

        var data = new FormData();
        data.append('name', values.name);
        data.append('comission_type', this.state.comission_type);
        data.append('price', this.state.price);
        data.append('percentual', this.state.percentual);
        data.append('status', this.state.status);
        data.append('level_max', this.state.level_max);
        data.append('price_type', this.state.price_type);

        this.props.createPriceComissionList(data, this.props.history)

    }

    render() {


        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Cadastro de Comissão</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title="Comissão"
                            breadcrumbItem="Cadastro" />
                        <Row>
                            <Col sm={7}>
                                <Card>
                                    <CardBody>
                                        <Row className="align-items-md-center  mb-3">
                                            <Col className="inner-custom-pagination d-flex">
                                                <div className="d-inline">
                                                    <span className="h4 card-title">Formulário</span>
                                                    <p className="card-title-desc mt-2">Preencha os campos abaixo, para
                                                        concluir o cadastro.</p>
                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <AvForm
                                                    className="form-horizontal"
                                                    onValidSubmit={this.handleValidSubmit}>

                                                    <Row>
                                                        <Col sm={8} className="mb-2">
                                                            <AvField
                                                                name="name"
                                                                label="Nome"
                                                                className="form-control"
                                                                placeholder="Digite o nome"
                                                                type="text"
                                                                required
                                                            />
                                                        </Col>
                                                        <Col sm={4}>
                                                            <AvField
                                                                value={this.state.status} onChange={this.handleFields}
                                                                type="select" name="select"
                                                                label={this.props.t("Status")}>
                                                                <option value="1">Ativo</option>
                                                                <option value="0">Inativo</option>
                                                            </AvField>
                                                        </Col>
                                                        <Col sm={4} className="mb-2">
                                                            <div className="form-group">
                                                                <label htmlFor=""
                                                                    className="control-label">Preço </label>
                                                                <Form.Control
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="00,00"
                                                                    name="price"
                                                                    as={InputMask}
                                                                    mask={"99,99"}
                                                                    onChange={(event) => {

                                                                        const { name, value } = event.target
                                                                        this.setState({ price: value })

                                                                    }}
                                                                    value={this.state.price ? this.state.price : this.props.price} />
                                                            </div>

                                                        </Col>
                                                        <Col sm={2} className="mb-2">
                                                            <div className="form-group">
                                                                <label htmlFor=""
                                                                    className="control-label">Percentual </label>
                                                                <Form.Control
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="0"
                                                                    name="percentual"
                                                                    onChange={(event) => {

                                                                        const { name, value } = event.target
                                                                        this.setState({ percentual: value })

                                                                    }}
                                                                    value={this.state.percentual ? this.state.percentual : this.props.percentual} />
                                                            </div>

                                                        </Col>
                                                        <Col sm={2} className="mb-2">
                                                            <div className="form-group">
                                                                <label htmlFor=""
                                                                    className="control-label">Nivel Máx </label>
                                                                <Form.Control
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="0"
                                                                    name="level_max"
                                                                    onChange={(event) => {

                                                                        const { name, value } = event.target
                                                                        this.setState({ level_max: value })

                                                                    }}
                                                                    value={this.state.level_max ? this.state.level_max : this.props.level_max} />
                                                            </div>

                                                        </Col>
                                                        <Col sm={4} className="pt-3"></Col>
                                                        <Col sm={6} className="pt-3">
                                                            <div className="mt-4" onChange={this.handleRadioButtom}>
                                                                <h5 className="font-size-14 mb-4">Tipo de preço a considerar</h5>
                                                                <div className="form-check ">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="price_type"
                                                                        id="price_type"
                                                                        value="1"
                                                                        defaultChecked

                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="price_type"
                                                                    >
                                                                        Tabela Comissão
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="price_type"
                                                                        id="price_type"
                                                                        value="2"

                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="price_type"
                                                                    >
                                                                        Nivel Comissão
                                                                    </label>
                                                                </div>
                                                            </div>

                                                        </Col>
                                                        <Col sm={6} className="pt-3">
                                                            <div className="mt-4" onChange={this.handleRadioButtom}>
                                                                <h5 className="font-size-14 mb-4">Aplicação da comissão</h5>
                                                                <div className="form-check ">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="comission_type"
                                                                        id="comission_type"
                                                                        value="1"
                                                                        defaultChecked

                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="comission_type"
                                                                    >
                                                                        Valor fixo
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="comission_type"
                                                                        id="comission_type"
                                                                        value="2"

                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="comission_type"
                                                                    >
                                                                        Perc. Agreg. Produto
                                                                    </label>
                                                                </div>
                                                            </div>

                                                        </Col>
                                                        <Col sm={12} className="pt-3">
                                                            <ButtonCustom
                                                                isLoading={this.props.loading}
                                                                size="sm"
                                                                clazzContainer="text-center"
                                                                clazz="btn btn-info w-md"
                                                                message_loaging="">
                                                                <button
                                                                    className="btn btn-info w-md"
                                                                    type="submit">
                                                                    Salvar
                                                                </button>
                                                            </ButtonCustom>
                                                        </Col>
                                                    </Row>
                                                </AvForm>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm={5}>
                                <Card>
                                    <CardBody>
                                        <Row className="align-items-md-center  mb-3">
                                            <Col className="inner-custom-pagination d-flex">
                                                <div className="d-inline">
                                                    <span className="h4 card-title">Leia-me</span>
                                                </div>
                                                <div className="text-md-right ms-auto">
                                                    <button
                                                        onClick={() => {
                                                            this.props.history.goBack()
                                                        }}
                                                        className="btn btn-dark btn-sm text-light"
                                                    >
                                                        <i className="bx bx-left-arrow-circle" />
                                                        <span> Voltar</span>
                                                    </button>
                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <div className='mt-3'>
                                                    Segue abaixo a instrução para configuração da tabela de comissão.

                                                    <li className='mt-3'><small>Tipo Comissão: Define a modalidade da aplicação da comissão sobre o produto/serviço <span className='text-danger'>(Considerar preço fixo ou percentual sobre produto.)</span>.</small></li>
                                                    <li className='mt-2'><small>Nivel Máx.: Determina o nivel de comissão permitido por indicação máximo.</small></li>
                                                    <li className='mt-2'><small>Valor Fixo: Determina o valor fixo comissionado pelo nivel da indicaçaõ.</small></li>
                                                    <li className='mt-2'><small>Perc. Agreg. Produto: Dertermina o percentual de comissão encima do valor do produto.</small></li>
                                                    <li className='mt-2'><small>Tabela Comissão: Dertermina que a regra se aplica na tabela original.</small></li>
                                                    <li className='mt-2'><small>Nivel Comissão: Obdece a configuração informada no cadastro do nivel.</small></li>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>


        )
    }


}

Index.propTypes = {
    t: PropTypes.any,
    loading: PropTypes.any,
    error: PropTypes.any,
    message: PropTypes.any,
    url_image: PropTypes.any,
    createPriceComissionList: PropTypes.func,
}


const mapStateToProps = state => {
    const { loading, error, message } = state.CreateOrEditProduct
    return { loading, error, message }
}

export default withTranslation()(withRouter(
    connect(mapStateToProps, {
        createPriceComissionList

    })(Index)
))


