import { takeEvery, put, call, takeLatest } from "redux-saga/effects"
import { NotificationManager } from "react-notifications";
import {
    CREATE_PRICE_COMISSION_LIST,
    EDIT_PRICE_COMISSION_LIST,
    FIND_BY_ID_PRICE_COMISSION_LIST
} from "./actionTypes"


import {
    createPriceComissionListSuccess,
    editPriceComissionListSuccess,
    findPriceComissionListIdSuccess,
    apiCreateOrEditPriceComissionListError
} from "./actions"


import {
    putEditPriceComissionList,
    postCreatePriceComissionList,
    getPriceComissionListById,

} from "../../helpers/price_comission_list_helper"

function* findByIdPriceComissionList({ payload: { id } }) {
    try {

        const response = yield call(getPriceComissionListById, id)

        yield put(findPriceComissionListIdSuccess(response))

    } catch (error) {
        yield put(apiCreateOrEditPriceComissionListError(error))
    }
}

function* createPriceComissionList({ payload: { params, existsFiles, history } }) {
    try {

        const response = yield call(postCreatePriceComissionList, params)
        const { message, id, error } = response;

        yield put(createPriceComissionListSuccess({ message, id, error, existsFiles }))


        NotificationManager.info(message, 'Aviso');

        if (!existsFiles) {
            history.goBack()
        }

    } catch (error) {
        yield put(apiCreateOrEditPriceComissionListError(error))
    }
}

function* editPriceComissionList({ payload: { params, existsFiles, files, history } }) {
    try {

        const response = yield call(putEditPriceComissionList, params)

        yield put(editPriceComissionListSuccess(response))

        const { message } = response;
        NotificationManager.info(message, 'Aviso');
        if (!existsFiles) {
            history.goBack()
        }


    } catch (error) {
        yield put(apiCreateOrEditPriceComissionListError(error))
    }
}


function* createOrEditPriceComissionListSaga() {
    yield takeEvery(CREATE_PRICE_COMISSION_LIST, createPriceComissionList)
    yield takeEvery(EDIT_PRICE_COMISSION_LIST, editPriceComissionList)
    yield takeEvery(FIND_BY_ID_PRICE_COMISSION_LIST, findByIdPriceComissionList)
}

export default createOrEditPriceComissionListSaga
