//LOGIN
export const POST_LOGIN = "/api/v1/access/login"
export const POST_PASSWORD_FORGET = "/api/v1/access/recovery"

//COUNTRY
export const GET_COUNTRIES_BY_ID = "/geo/countries"
export const GET_COUNTRIES_PAGINATE = "/geo/countries/paginate"
export const GET_COUNTRIES_PAGINATE_FILTERS = "/geo/countries/filters"
export const POST_CREATE_COUNTRY = "/geo/countries"
export const PUT_EDIT_COUNTRY = "/geo/countries"
export const DELETE_COUNTRY = "/geo/countries"

//STATE
export const GET_STATE_BY_ID = "/geo/states"
export const GET_STATE_PAGINATE = "/geo/states/paginate"
export const GET_STATE_PAGINATE_FILTERS = "/geo/states/filters"
export const POST_CREATE_STATE = "/geo/states"
export const PUT_EDIT_STATE = "/geo/states"
export const DELETE_STATE = "/geo/states"

//CITY
export const GET_CITY_BY_ID = "/geo/cities"
export const GET_CITY_PAGINATE = "/geo/cities/paginate"
export const GET_CITY_PAGINATE_FILTERS = "/geo/cities/filters"
export const POST_CREATE_CITY = "/geo/cities"
export const PUT_EDIT_CITY = "/geo/cities"
export const DELETE_CITY = "/geo/cities"

//COMPANIES
export const GET_COMPANIES_BY_ID = "/api/v1/companies"
export const GET_COMPANIES_ABOUT_US = "/api/v1/companies/aboutus"
export const GET_COMPANIES_PAGINATE = "/api/v1/companies/paginate"
export const GET_COMPANIES_PAGINATE_FILTERS = "/api/v1/companies/filters"
export const POST_CREATE_COMPANY = "/api/v1/companies"
export const POST_UPLOAD_COMPANY = "/api/v1/companies/upload"
export const PUT_EDIT_COMPANY = "/api/v1/companies"
export const DELETE_COMPANY = "/api/v1/companies"
export const GET_SEARCH_COMPANY = "/search/companies"
export const GET_COMPANIES_CRT = "/fiscal/nf/classicacao/empresas"
export const POST_CREATE_CRT_COMPANY = "/fiscal/nf/classicacao/empresas"

//Schedulers company
export const GET_SCHEDULER_COMPANY_BY_ID = "/api/v1/companies/schedules"
export const GET_SCHEDULER_COMPANY_PAGINATE = "/api/v1/companies/schedules/paginate"
export const GET_SCHEDULER_COMPANY_PAGINATE_FILTERS = "/api/v1/category/edges/filters"
export const POST_CREATE_SCHEDULER_COMPANY = "/api/v1/companies/schedules"
export const PUT_EDIT_SCHEDULER_COMPANY = "/api/v1/companies/schedules"
export const DELETE_SCHEDULER_COMPANY = "/api/v1/companies/schedules"



//GEO
export const GET_GEO_SEARCH_ADDRESS = "/geo/search/map/address"
export const GET_GEO_REVERSE = "/geo/search/map/reverse"

//VIACEP
export const GET_VIA_CEP = "/geo/search/map/reverse"

//SETTINGS
export const GET_SETTINGS_BY_ID = "/settings"
export const GET_SETTINGS_BY_KEY = "/settings/key"
export const GET_SETTINGS_PAGINATE = "/settings/paginate"
export const GET_SETTINGS_PAGINATE_FILTERS = "/settings/filters"
export const POST_CREATE_SETTINGS = "/settings"
export const POST_CREATE_OR_EDIT_SETTINGS = "/settings/create-or-edit"
export const PUT_EDIT_SETTINGS = "/settings"
export const DELETE_SETTINGS = "/settings"


//CUSTOMERS
export const GET_CUSTOMER_BY_ID = "/api/v1/companies"
export const DELETE_CUSTOMER = "/api/v1/companies"
export const POST_CREATE_CUSTOMER = "/api/v1/companies"
export const PUT_EDIT_CUSTOMER = "/api/v1/companies"
export const GET_CUSTOMER_PAGINATE = "/api/v1/companies/paginate"

//PRODUCTS
export const GET_PRODUCTS_BY_ID = "/api/v1/products"
export const DELETE_PRODUCTS = "/api/v1/products"
export const POST_CREATE_PRODUCTS = "/api/v1/products"
export const PUT_EDIT_PRODUCTS = "/api/v1/products"
export const GET_PRODUCTS_PAGINATE = "/api/v1/products/paginate"
export const POST_UPLOAD_PRODUCTS = "/api/v1/products/upload";

//PRICE LIST
export const GET_PRICE_COMISSION_LIST_BY_ID = "/api/v1/price-list/comission"
export const DELETE_PRICE_COMISSION_LIST = "/api/v1/price-list/comission"
export const POST_CREATE_PRICE_COMISSION_LIST = "/api/v1/price-list/comission"
export const PUT_EDIT_PRICE_COMISSION_LIST = "/api/v1/price-list/comission"
export const GET_PRICE_COMISSION_LIST_PAGINATE = "/api/v1/price-list/comission/paginate"

//PRODUCTS PRICE LIST
export const GET_PRODUCTS_PRICE_LIST_BY_ID = "/api/v1/products/price-list"
export const DELETE_PRODUCTS_PRICE_LIST = "/api/v1/products/price-list"
export const POST_CREATE_PRODUCTS_PRICE_LIST = "/api/v1/products/price-list"
export const PUT_EDIT_PRODUCTS_PRICE_LIST = "/api/v1/products/price-list"
export const GET_PRODUCTS_PRICE_LIST_PAGINATE = "/api/v1/products/price-list/paginate"


//LEVEL LIST
export const GET_LEVEL_COMISSION_LIST_BY_ID = "/api/v1/price-list/level"
export const DELETE_LEVEL_COMISSION_LIST = "/api/v1/price-list/level"
export const POST_CREATE_LEVEL_COMISSION_LIST = "/api/v1/price-list/level"
export const PUT_EDIT_LEVEL_COMISSION_LIST = "/api/v1/price-list/level"
export const GET_LEVEL_COMISSION_LIST_PAGINATE = "/api/v1/price-list/level/paginate"

//LOYALTY TRANSACTION
export const GET_LOYALTY_TRANSACTION_BY_ID = "/api/v1/loyalty/transaction"
export const DELETE_LOYALTY_TRANSACTION = "/api/v1/loyalty/transaction"
export const POST_CREATE_LOYALTY_TRANSACTION = "/api/v1/loyalty/transaction"
export const PUT_EDIT_LOYALTY_TRANSACTION = "/api/v1/loyalty/transaction"
export const GET_LOYALTY_TRANSACTION_PAGINATE = "/api/v1/loyalty/transaction/paginate"




