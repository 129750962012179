import React from 'react';
import { Col, Row, Spinner } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { apiPaginateLevelComissionListError, deleteLevelComissionList, allLevelComissionListPaginate } from "../../../store/actions";
import PropTypes from "prop-types";
import State from "../../../store/state/table/reducer";



class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}

        this._delete_item = this._delete_item.bind(this);
        this._showingpaginate = this._showingpaginate.bind(this);
        this.handleClickPagination = this.handleClickPagination.bind(this);
        this._showingstatuslabel = this._showingstatuslabel.bind(this);
    }

    componentDidMount() {
        this._all_by_paginate(1, 10)
    }

    _showingstatuslabel(value) {
        switch (parseInt(value)) {
            case 0:
                return (<span className="badge pill bg-danger p-1" style={{ fontSize: '90%' }}>Inativo</span>);
            case 1:
                return (<span className="badge pill bg-success p-1" style={{ fontSize: '90%' }}> Ativo </span>);
            default:
                return (<span className="badge pill bg-success p-1" style={{ fontSize: '90%' }}> Ativo </span>);
        }

    }

    async handleClickPagination(value) {
        await this._all_by_paginate(value, 10)
    }

    async _delete_item(id) {
        this.props.deleteLevelComissionList(id, this.props.history)

    }

    async _all_by_paginate(page, limit) {

        this.props.allLevelComissionListPaginate({
            page, limit
        })
    }

    _showingpaginate(current_page, last_page, total, from, to) {
        let pages = [];

        if (to <= total) {

            for (let i = 1; i <= last_page; i++) {

                pages.push(i)

            }

        }
        if (current_page <= last_page) {
            return (
                <div className="clearfix">
                    <ul className="pagination pagination-sm m-0 float-right">
                        <li className="page-item" key={11 + 'ss'}>
                            <button disabled={1 === current_page}
                                className={`page-link ${1 === current_page ? 'text-light bg-dark' : ''}`}
                                onClick={() => this.handleClickPagination(1)}>Primeiro
                            </button>
                        </li>
                        {
                            pages.map((page, index) => {
                                return (<li key={index} className="page-item">
                                    <button disabled={page === current_page}
                                        className={`page-link ${page === current_page ? 'text-light bg-dark' : ''}`}
                                        onClick={() => this.handleClickPagination(page)}>{page}</button>
                                </li>)
                            })
                        }
                        <li key={12 + 'ss'} className="page-item ">
                            <button disabled={last_page === current_page}
                                className={`page-link ${last_page === current_page ? 'text-light bg-dark' : ''}`}
                                onClick={() => this.handleClickPagination(last_page)}>Último
                            </button>
                        </li>
                    </ul>
                </div>
            )
        }
    }

    render() {
        const { columns, edit, route_edit, showStatus } = this.props;

        return (
            <div>
                {
                    this.props.loading
                        ?
                        <div className="text-center" style={{ width: '100%', position: 'absolute', paddingBottom: 20 }}>
                            <Spinner size="sm" animation="border"
                                style={{ marginBottom: 20 }}
                                variant="success" />
                        </div>
                        :
                        <>
                            <Row className="align-items-md-center  mb-3">
                                <Col className="inner-custom-pagination d-flex">
                                    <div className="d-inline">
                                        <span className="text-muted">

                                        </span>
                                    </div>
                                    <div className="text-md-right ms-auto">
                                        <Link
                                            to={'register-lv-comission'}
                                            className="btn btn-dark btn-sm text-light"
                                        >
                                            <i className="bx bx-plus-circle" />
                                            <span> Novo Cadastro</span>
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                {columns.map(
                                                    (column, index) => {
                                                        return (
                                                            <th key={index} className="text-center">{column.name}</th>
                                                        )
                                                    })
                                                }

                                            </tr>
                                        </thead>
                                        <tbody>

                                            {

                                                this.props.rows &&
                                                    this.props.rows.length > 0
                                                    ?
                                                    this.props.rows.map((row, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className="text-center" >
                                                                    <p>{row.price_list.name}</p>
                                                                    <p>Nivel: {row.level}</p>
                                                                </td>
                                                                <td className="text-center" >{row.comission_type == 1 ? 'Valor Fixo' : 'Percentual'}</td>
                                                                {showStatus !== false &&
                                                                    <td className="text-center" >
                                                                        {
                                                                            this._showingstatuslabel(row.status)
                                                                        }
                                                                    </td>
                                                                }

                                                                <td className="text-center">
                                                                    <div className="btn-group">

                                                                        {edit !== false &&
                                                                            <Link className="btn btn-dark btn-sm"
                                                                                to={{ pathname: route_edit.concat('/' + row.id).concat('/level') }}>
                                                                                <i className="fas fa-edit"></i>
                                                                            </Link>
                                                                        }

                                                                        <a onClick={() => confirm('Você tem certeza?') ? this._delete_item(row.id) : ''}
                                                                            className="btn btn-danger btn-sm">
                                                                            <i className="fas fa-trash text-light"></i>
                                                                        </a>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        {columns.map(item => <td></td>)}
                                                    </tr>
                                            }


                                        </tbody>
                                    </table>
                                </Col>

                            </Row>
                            <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                    <div className="d-inline">
                                        <span className="mt-1 text-muted">
                                            {this.props.from} de {this.props.total} - página {this.props.current_page} de {this.props.last_page}
                                        </span>
                                    </div>
                                    <div className="text-md-right ms-auto">
                                        {this._showingpaginate(
                                            this.props.current_page, this.props.last_page, this.props.total,
                                            this.props.from, this.props.to)}
                                    </div>
                                </Col>
                            </Row>
                        </>
                }
            </div>


        )
    }


}

State.propTypes = {
    apiPaginateLevelComissionListError: PropTypes.any,
    error: PropTypes.any,
    loading: PropTypes.any,
    loading_delete: PropTypes.any,
    rows: PropTypes.any,
    current_page: PropTypes.number,
    last_page: PropTypes.number,
    total: PropTypes.number,
    from: PropTypes.number,
    to: PropTypes.number,
    allLevelComissionListPaginate: PropTypes.func,
    deleteLevelComissionList: PropTypes.func
}

const mapStateToProps = state => {
    const {
        loading,
        rows,
        current_page,
        last_page,
        total,
        from,
        to,
        loading_delete
    } = state.LevelListComission
    return {
        loading,
        rows,
        current_page,
        last_page,
        total,
        from,
        to,
        loading_delete
    }
}

export default withRouter(
    connect(mapStateToProps, {
        allLevelComissionListPaginate,
        deleteLevelComissionList,
        apiPaginateLevelComissionListError,

    })(Index)
) 
