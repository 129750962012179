import {
    CREATE_CUSTOMERS,
    CREATE_CUSTOMERS_SUCCESS,
    EDIT_CUSTOMERS,
    EDIT_CUSTOMERS_SUCCESS,
    FIND_BY_ID_CUSTOMERS,
    FIND_BY_ID_CUSTOMERS_SUCCESS,
    CREATE_OR_EDIT_CUSTOMERS_API_ERROR
} from "./actionTypes"


export const createCustomer = (params, existsFiles,   history) => {
    return {
        type: CREATE_CUSTOMERS,
        payload: {params,existsFiles,  history},
    }
}

export const createCustomerSuccess = params => {
    return {
        type: CREATE_CUSTOMERS_SUCCESS,
        payload: params,
    }
}

export const editCustomer = (params, history) => {
    return {
        type: EDIT_CUSTOMERS,
        payload: {params, history},
    }
}

export const editCustomerSuccess = result => {
    return {
        type: EDIT_CUSTOMERS_SUCCESS,
        payload: {result},
    }
}

export const findByIdCustomer = id => {
    return {
        type: FIND_BY_ID_CUSTOMERS,
        payload: {id},
    }
}

export const findCustomerIdSuccess = result => {
    return {
        type: FIND_BY_ID_CUSTOMERS_SUCCESS,
        payload: result,
    }
}

export const apiCreateOrEditCustomerError = error => {
    return {
        type: CREATE_OR_EDIT_CUSTOMERS_API_ERROR,
        payload: error,
    }
}

