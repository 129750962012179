export const CREATE_PRODUCTS = "CREATE_PRODUCTS"
export const CREATE_PRODUCTS_SUCCESS = "CREATE_PRODUCTS_SUCCESS"

export const EDIT_PRODUCTS = "EDIT_PRODUCTS"
export const EDIT_PRODUCTS_SUCCESS = "EDIT_PRODUCTS_SUCCESS"

export const UPLOAD_PRODUCTS = "UPLOAD_PRODUCTS"
export const UPLOAD_PRODUCTS_SUCCESS = "UPLOAD_PRODUCTS_SUCCESS"


export const FIND_BY_ID_PRODUCTS = "FIND_BY_ID_PRODUCTS"
export const FIND_BY_ID_PRODUCTS_SUCCESS = "FIND_BY_ID_PRODUCTS_SUCCESS"

export const CREATE_OR_EDIT_PRODUCTS_API_ERROR = "CREATE_OR_EDIT_PRODUCTS_API_ERROR"
