import React from 'react';
import MetaTags from 'react-meta-tags'
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumbs from "../../../components/Breadcrumb";
import { connect } from "react-redux";
import { editProductsPriceList, findByIdProductsPriceList } from "../../../store/actions"
import { AvField, AvForm } from "availity-reactstrap-validation"
import ButtonCustom from "../../../components/ButtonCustom";
import { Form } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import Autocomplete from 'components/Autocomplete'

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            status: 1,
            price_type: 1,
            level_max: 5,
            comission_type: 1,

            price_list: null,
            product: null,
            company: null,
        }

        this.handleValidSubmit = this.handleValidSubmit.bind(this)
        this.handleFields = this.handleFields.bind(this)
        this.handleRadioButtom = this.handleRadioButtom.bind(this)
    }

    componentDidMount() {
        const { entity_id } = this.props.match.params
        this.props.findByIdProductsPriceList(entity_id);

    }
    handleRadioButtom(event) {
        const { id, name, value } = event.target
        let newobj = {}
        newobj[name] = this.state[name] === 1 ? 0 : 1
        this.setState(newobj)

    }

    handleFields(event) {

        const { value } = event.target

        this.setState({ status: value })
    }



    handleValidSubmit(event, values) {

        this.props.editProductsPriceList({
            id: this.props.id,
            p_com_list_id: this.state.p_com_list_id,
            price: this.state.price,
            company_id: this.state.company_id,
            status: this.state.status,
            product_id: this.state.product_id,

        }, this.props.history)

    }

    render() {


        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Cadastro de Preço</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title="Preço"
                            breadcrumbItem="Cadastro" />
                        <Row>
                            <Col sm={7}>
                                <Card>
                                    <CardBody>
                                        <Row className="align-items-md-center  mb-3">
                                            <Col className="inner-custom-pagination d-flex">
                                                <div className="d-inline">
                                                    <span className="h4 card-title">Formulário</span>
                                                    <p className="card-title-desc mt-2">Preencha os campos abaixo, para
                                                        concluir o cadastro.</p>
                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <AvForm
                                                    className="form-horizontal"
                                                    onValidSubmit={this.handleValidSubmit}>

                                                    <Row>
                                                        <Col sm={8} className="mb-2">
                                                            <Autocomplete
                                                                onSourceSelect={(d, e) => this.handleAutocomplete(d, e)}
                                                                nameFieldSelect='p_com_list_id'
                                                                limit={10}
                                                                inputProps={{
                                                                    id: 'price_comission_list',
                                                                    name: 'price_comission_list',
                                                                    label: "Tabela Comissão",
                                                                    placeholder: "Pesquisar comissão..",
                                                                    param_id: '',
                                                                    value: this.state.price_list ? this.state.price_list : this.props.price_list,
                                                                    url: 'search/tab-comission'
                                                                }} />

                                                        </Col>
                                                        <Col sm={4}>
                                                            <AvField
                                                                value={this.state.status} onChange={this.handleFields}
                                                                type="select" name="select"
                                                                label={this.props.t("Status")}>
                                                                <option value="1">Ativo</option>
                                                                <option value="0">Inativo</option>
                                                            </AvField>
                                                        </Col>
                                                        <Col sm={6} className="mb-2">
                                                            <Autocomplete
                                                                onSourceSelect={(d, e) => this.handleAutocomplete(d, e)}
                                                                nameFieldSelect='product_id'
                                                                limit={10}
                                                                inputProps={{
                                                                    id: 'product',
                                                                    name: 'product',
                                                                    label: "Produto",
                                                                    placeholder: "Pesquisar produto..",
                                                                    param_id: '',
                                                                    value: this.state.product ? this.state.product : this.props.product,
                                                                    url: 'search/products'
                                                                }} />

                                                        </Col>
                                                        <Col sm={6} className="mb-2">
                                                            <Autocomplete
                                                                onSourceSelect={(d, e) => this.handleAutocomplete(d, e)}
                                                                nameFieldSelect='company_id'
                                                                limit={10}
                                                                inputProps={{
                                                                    id: 'company',
                                                                    name: 'company',
                                                                    label: "Empresa",
                                                                    placeholder: "Pesquisar empresa..",
                                                                    param_id: '',
                                                                    value: this.state.company ? this.state.company : this.props.company,
                                                                    url: 'search/companies'
                                                                }} />

                                                        </Col>
                                                        <Col sm={4} className="mb-2">
                                                            <div className="form-group">
                                                                <label htmlFor=""
                                                                    className="control-label">Preço </label>
                                                                <Form.Control
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="00,00"
                                                                    name="price"
                                                                    as={InputMask}
                                                                    mask={"99,99"}
                                                                    onChange={(event) => {

                                                                        const { name, value } = event.target
                                                                        this.setState({ price: value })

                                                                    }}
                                                                    value={this.state.price ? this.state.price : this.props.price} />
                                                            </div>

                                                        </Col>
                                                        <Col sm={12} className="pt-3">
                                                            <ButtonCustom
                                                                isLoading={this.props.loading}
                                                                size="sm"
                                                                clazzContainer="text-center"
                                                                clazz="btn btn-info w-md"
                                                                message_loaging="">
                                                                <button
                                                                    className="btn btn-info w-md"
                                                                    type="submit">
                                                                    Salvar
                                                                </button>
                                                            </ButtonCustom>
                                                        </Col>
                                                    </Row>
                                                </AvForm>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm={5}>
                                <Card>
                                    <CardBody>
                                        <Row className="align-items-md-center  mb-3">
                                            <Col className="inner-custom-pagination d-flex">
                                                <div className="d-inline">
                                                    <span className="h4 card-title">Leia-me</span>
                                                </div>
                                                <div className="text-md-right ms-auto">
                                                    <button
                                                        onClick={() => {
                                                            this.props.history.goBack()
                                                        }}
                                                        className="btn btn-dark btn-sm text-light"
                                                    >
                                                        <i className="bx bx-left-arrow-circle" />
                                                        <span> Voltar</span>
                                                    </button>
                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <div className='mt-3'>
                                                    Segue abaixo a instrução para configuração do produto.

                                                    <li className='mt-3'><small>Tabela Comissão: Indica para o sistema em qual regra de comissão o produto/serviço pertence.</small></li>
                                                    <li className='mt-2'><small>Produto: Pesquise e selecione o produto ou serviço a ser configurado</small></li>
                                                    <li className='mt-2'><small>Empresa: Determina para qual empresa ficará disponivel a tabela de preço do produto</small></li>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>


        )
    }


}

Index.propTypes = {
    t: PropTypes.any,
    loading: PropTypes.any,
    error: PropTypes.any,
    message: PropTypes.any,
    url_image: PropTypes.any,
    editProductsPriceList: PropTypes.func,
    findByIdProductsPriceList: PropTypes.func,

    price: PropTypes.any,
    id: PropTypes.any,
    company: PropTypes.any,
    product: PropTypes.any,
    price_list: PropTypes.any,
}

const mapStateToProps = state => {
    const {
        loading,
        error,
        message,
        status,
        price,
        id,
        company,
        product,
        price_list,
    } = state.CreateOrEditProductsPriceList
    return {
        loading,
        error,
        message,
        price,
        id,
        company,
        product,
        price_list,
    }
}

export default withTranslation()(withRouter(
    connect(mapStateToProps, {
        editProductsPriceList,
        findByIdProductsPriceList

    })(Index)
))


