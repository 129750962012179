import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import { NotificationManager } from "react-notifications";


const getLoyaltyTransactionById = data => get(url.GET_LOYALTY_TRANSACTION_BY_ID.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delLoyaltyTransaction = data => del(url.DELETE_LOYALTY_TRANSACTION.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateLoyaltyTransaction = data => post(url.POST_CREATE_LOYALTY_TRANSACTION, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditLoyaltyTransaction = data => put(url.PUT_EDIT_LOYALTY_TRANSACTION, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getLoyaltyTransactionPaginate = ({ page, limit }) => get(url.GET_LOYALTY_TRANSACTION_PAGINATE, { params: { page, limit } })
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

export {
    getLoyaltyTransactionPaginate,
    putEditLoyaltyTransaction,
    postCreateLoyaltyTransaction,
    delLoyaltyTransaction,
    getLoyaltyTransactionById,
    
}
