import React from 'react';
import MetaTags from 'react-meta-tags'
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumbs from "../../../components/Breadcrumb";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import { connect } from "react-redux";
import { google_maps } from "../../../config";
import { searchAddress, reverseCoordinates, editCompany, findByIdCompany } from "../../../store/actions"
import { findPostalCode } from "../../../helpers/viacep_helper"
import { AvField, AvForm } from "availity-reactstrap-validation"
import ButtonCustom from "../../../components/ButtonCustom";
import InputMask from "react-input-mask";
import GenericsServiceAPI from "../../../helpers/GenericsServicesAPI";
import { NotificationManager } from "react-notifications";

const LoadingContainer = () => <div>Loading...</div>

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            status: 1,
            cnpj: '',
            image: '',
            postal_code: '',
            phone: '',

            place: '',
            district: '',
            number: '',
            region: '',
            address: '',

            file: null,
            existsFile: false,
            isFindCep: false,
        }

        this.handleValidSubmit = this.handleValidSubmit.bind(this)
        this.handleFields = this.handleFields.bind(this)
        this.handleDrop = this.handleDrop.bind(this);
        this.find_postal_code = this.find_postal_code.bind(this);
        this.sendUpload = this.sendUpload.bind(this)

    }

    componentDidMount() {
        const { entity_id } = this.props.match.params
        this.props.findByIdCompany(entity_id)



    }
    sendUpload(formData, url) {

        GenericsServiceAPI.upload(formData, url)
            .then(response => {
                const { message } = response;

                this.props.existsFiles = false
                this.props.loading = false
                NotificationManager.info(message, 'Aviso!');
                history.goBack()

            })
            .catch(err => {
                this.props.loading = false
                NotificationManager.warning(err, 'Ops!', 3000);
            })
            .then(response => {

            });
    }

    find_postal_code() {

        const { postal_code } = this.state

        if (postal_code && postal_code.replace(/\D/g, '').length == 8) {


            this.setState({
                isFindCep: true
            })

            findPostalCode(postal_code.replace(/\D/g, ''))
                .then(response => response.json())
                .then(response => {

                    this.setState({
                        isFindCep: false,
                        postal_code: response.cep,
                        address: response.logradouro,
                        district: response.bairro,
                        city: response.localidade,
                        state: response.uf,
                        region: response.localidade + '-' + response.uf,

                    })

                    this.props.searchAddress(response.cep +
                        ',' + response.logradouro +
                        ',' + response.bairro +
                        ',' + response.localidade +
                        '-' + response.uf)

                    let number = document.querySelector('[name="number"]')
                    if (number) {
                        number.focus()
                    }

                })


        }

    }


    handleFields(event) {

        const { name, value } = event.target

        let tempState = {}
        tempState[name] = value

        this.setState(tempState)

    }

    handleDrop(e) {

        let reader = new FileReader();
        let file = e[0];

        reader.onloadend = () => {
            this.setState({
                file: file,
                existsFile: true,
                image: reader.result
            });
        }

        reader.readAsDataURL(file)

    }

    handleValidSubmit(event, values) {
        this.props.editCompany({
            id: this.props.id,
            fantasy_name: values.fantasy_name,
            company_name: values.company_name,
            cnpj: this.state.cnpj ? this.state.cnpj : this.props.cnpj,
            email: values.email,
            phone: this.state.phone ? this.state.phone : this.props.phone,

            postal_code: this.state.postal_code ? this.state.postal_code : this.props.postal_code,
            address: this.state.address ? this.state.address : this.props.address,
            district: this.state.district ? this.state.district : this.props.district,
            number: this.state.number ? this.state.number : this.props.number,
            region: this.state.region ? this.state.region : this.props.region,
            city: this.state.city,
            state: this.state.state,

            status: this.state.status ? this.state.status : this.props.status,
            lat: this.props.coordinates.lat,
            lng: this.props.coordinates.lng
        }, this.props.history)

    }

    render() {
        if (this.props.coordinates.lat === 0) {
            this.props.coordinates.lat = this.props.lat
            this.props.coordinates.lng = this.props.lng
        }

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Editar Empresa</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title="Empresa"  breadcrumbItem="Editar" />
                        <Row>
                            {
                                this.props.loading_form
                                    ?
                                    <Col className="col-12">
                                        <Card>
                                            <CardBody>
                                                <Row className="align-items-md-center  mb-3">
                                                    <Col className="inner-custom-pagination d-flex">
                                                        <div className="text-center" style={{
                                                            width: '100%',
                                                            position: 'absolute',
                                                            paddingBottom: 20
                                                        }}>
                                                            <Spinner size="sm" animation="border"
                                                                style={{ marginBottom: 20 }}
                                                                variant="success" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>

                                    </Col>
                                    :
                                    <Col sm={12}>
                                        <AvForm
                                            className="form-horizontal"
                                            onValidSubmit={this.handleValidSubmit}>
                                            <Row>
                                                <Col sm={7}>
                                                    <Card>
                                                        <CardBody>
                                                            <Row className="align-items-md-center  mb-3">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">
                                                                        <span
                                                                            className="h4 card-title">{this.props.t("Form")}</span>
                                                                        <p className="card-title-desc mt-2">Preencha os campos
                                                                            abaixo, para
                                                                            concluir o cadastro.</p>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={12}>


                                                                    <Row>
                                                                        <Col sm={8} className="mb-2">
                                                                            <AvField
                                                                                name="company_name"
                                                                                label="Razão Social"
                                                                                value={this.props.company_name}
                                                                                className="form-control"
                                                                                placeholder="Digite a razão social"
                                                                                type="text"
                                                                                required
                                                                            />
                                                                        </Col>
                                                                        <Col sm={4} className="mb-2">
                                                                            <AvField
                                                                                value={this.props.status}
                                                                                onChange={this.handleFields}
                                                                                type="select" name="select"
                                                                                label={this.props.t("Status")}>
                                                                                <option value="1">Ativo</option>
                                                                                <option value="0">Inativo</option>
                                                                            </AvField>
                                                                        </Col>
                                                                        <Col sm={4} className="mb-2">
                                                                            <div className="form-group">
                                                                                <label>CNPJ</label>
                                                                                <InputMask
                                                                                    className="form-control"
                                                                                    mask={"99.999.999/9999-99"}
                                                                                    name="cnpj"
                                                                                    onChange={this.handleFields}
                                                                                    value={this.props.cnpj} />
                                                                            </div>
                                                                        </Col>
                                                                        <Col sm={8} className="mb-2">
                                                                            <AvField
                                                                                name="fantasy_name"
                                                                                label="Nome Fantasia"
                                                                                className="form-control"
                                                                                value={this.props.fantasy_name}
                                                                                placeholder="Digite o nome fantasia"
                                                                                type="text"
                                                                                required
                                                                            />
                                                                        </Col>

                                                                        <Col sm={6} className="mb-2">
                                                                            <AvField
                                                                                name="email"
                                                                                label={this.props.t("E-mail")}
                                                                                type="text"
                                                                                value={this.props.email}
                                                                                placeholder="Digite o e-mail"
                                                                                required
                                                                            />
                                                                        </Col>
                                                                        <Col sm={6} className="mb-2">

                                                                            <div className="form-group">
                                                                                <label>Telefone</label>
                                                                                <InputMask
                                                                                    className="form-control"
                                                                                    mask="(99) 99999-9999"
                                                                                    name="phone"
                                                                                    onChange={this.handleFields}
                                                                                    value={this.props.phone} />
                                                                            </div>

                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                <Col sm={5}>
                                                    <Card>
                                                        <CardBody>
                                                            <Row className="align-items-md-center  mb-3">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">
                                                                        <span
                                                                            className="h4 card-title">Configurações</span>
                                                                        <p className="card-title-desc mt-3">Informe a configuração</p>
                                                                    </div>
                                                                    <div className="text-md-right ms-auto">
                                                                        <a
                                                                            onClick={() => {
                                                                                this.props.history.goBack()
                                                                            }}
                                                                            className="btn btn-dark btn-sm text-light"
                                                                        >
                                                                            <i className="bx bx-left-arrow-circle" />
                                                                            <span> Voltar</span>
                                                                        </a>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={12}>
                                                                    <div className="list-group">
                                                                        <Link
                                                                            to={{ pathname: '/' + this.props.id + '/about-us' }}
                                                                            className="list-group-item">
                                                                            Sobre
                                                                            <span
                                                                                className="badge rounded-pill bg-dark float-end">Configurar</span>
                                                                        </Link>
                                                                        <Link
                                                                            to={{ pathname: '/' + this.props.id + '/schedulers' }}
                                                                            className="list-group-item">
                                                                            Horários de atendimento
                                                                            <span
                                                                                className="badge rounded-pill bg-dark float-end">Configurar</span>
                                                                        </Link>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col sm={7}>
                                                    <Card>
                                                        <CardBody>
                                                            <Row className="align-items-md-center  mb-3">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">
                                                                        <span
                                                                            className="h4 card-title">Endereço</span>
                                                                        <p className="card-title-desc mt-2">Preencha os campos
                                                                            abaixo, com o endereço</p>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={12}>

                                                                    <Row>
                                                                        <Col sm={3} className="mb-2">
                                                                            <AvField
                                                                                name="postal_code"
                                                                                label="CEP"
                                                                                className="form-control"
                                                                                placeholder="Digite cep"
                                                                                onChange={this.handleFields}
                                                                                onBlur={this.find_postal_code}
                                                                                value={this.props.postal_code}
                                                                                type="text"
                                                                                required
                                                                            />
                                                                            {
                                                                                this.state.isFindCep
                                                                                &&
                                                                                <Spinner size="sm" animation="border"
                                                                                    variant="dark" />
                                                                            }

                                                                        </Col>

                                                                        <Col sm={9} className="mb-2">
                                                                            <AvField
                                                                                name="address"
                                                                                label="Endereço"
                                                                                className="form-control"
                                                                                placeholder="Digite o logradouro"
                                                                                type="text"
                                                                                value={this.props.address}
                                                                                required
                                                                            />
                                                                        </Col>
                                                                        <Col sm={3} className="mb-2">
                                                                            <AvField
                                                                                name="number"
                                                                                label="Nº"
                                                                                className="form-control"
                                                                                placeholder="Nº"
                                                                                value={this.props.number}
                                                                                type="text"
                                                                                required
                                                                            />
                                                                        </Col>

                                                                        <Col sm={9} className="mb-2">
                                                                            <AvField
                                                                                name="district"
                                                                                label="Bairro"
                                                                                type="text"
                                                                                value={this.props.district}
                                                                                placeholder="Digite o bairro"
                                                                                required
                                                                            />
                                                                        </Col>
                                                                        <Col sm={12} className="mb-2">
                                                                            <AvField
                                                                                name="region"
                                                                                label="Região"
                                                                                type="text"
                                                                                value={this.props.region}
                                                                                placeholder="Digite a cidade/estado uf"
                                                                                required
                                                                            />
                                                                        </Col>

                                                                        <Col sm={12} className="pt-3">
                                                                            <ButtonCustom
                                                                                isLoading={this.props.loading}
                                                                                size="sm"
                                                                                clazzContainer="text-center"
                                                                                clazz="btn btn-info w-md"
                                                                                message_loaging="">
                                                                                <button
                                                                                    className="btn btn-info w-md"
                                                                                    type="submit">
                                                                                    Salvar
                                                                                </button>
                                                                            </ButtonCustom>
                                                                        </Col>
                                                                    </Row>

                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                <Col sm={5}>
                                                    <Card>
                                                        <CardBody>
                                                            <Row className="align-items-md-center  mb-3">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">
                                                                        <span
                                                                            className="h4 card-title">{this.props.t("Map")}</span>
                                                                        <p className="card-title-desc mt-3">Acompanhe a
                                                                            localidade no
                                                                            mapa</p>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={12}>
                                                                    <div id="gmaps-makers" className="gmaps"
                                                                        style={{ position: 'relative' }}>
                                                                        {
                                                                            this.props.coordinates.lat != 0 &&
                                                                            <Map
                                                                                google={this.props.google}
                                                                                style={{ width: '100%', height: '100%' }}
                                                                                zoom={14}
                                                                                loaded={this.props.loading_map}
                                                                                center={this.props.coordinates}
                                                                                initialCenter={this.props.coordinates}
                                                                            >
                                                                                <Marker
                                                                                    position={this.props.coordinates}
                                                                                />
                                                                            </Map>
                                                                        }
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </AvForm>
                                    </Col>
                            }

                        </Row>
                    </Container>
                </div>
            </React.Fragment>


        )
    }


}

Index.propTypes = {
    google: PropTypes.object,
    location: PropTypes.object,
    t: PropTypes.any,
    loading_map: PropTypes.any,
    coordinates: PropTypes.object,
    address: PropTypes.any,

    loading_form: PropTypes.any,
    loading: PropTypes.any,
    error: PropTypes.any,
    message: PropTypes.any,

    code: PropTypes.any,
    fantasy_name: PropTypes.any,
    company_name: PropTypes.any,
    cnpj: PropTypes.any,
    email: PropTypes.any,
    phone: PropTypes.any,
    status: PropTypes.any,
    postal_code: PropTypes.any,


    district: PropTypes.any,
    number: PropTypes.any,
    region: PropTypes.any,
    image: PropTypes.any,
    id: PropTypes.any,

    lat: PropTypes.any,
    lng: PropTypes.any,
    data64: PropTypes.any,


    searchAddress: PropTypes.func,
    reverseCoordinates: PropTypes.func,
    editCompany: PropTypes.func,
    findByIdCompany: PropTypes.func,

}


const mapStateToProps = state => {
    const { loading_map, coordinates } = state.GeoLocation
    const {
        loading,
        loading_form,
        error,
        message,

        data64,
        code,
        fantasy_name,
        company_name,
        cnpj,
        email,
        phone,
        status,
        postal_code,
        address,
        district,
        number,
        region,
        image,
        lat,
        lng,
        id
    } = state.CreateOrEditCompanies
    return {
        loading_map,
        loading_form,
        coordinates,
        loading,
        data64,
        error,
        message,
        code,
        fantasy_name,
        company_name,
        cnpj,
        email,
        phone,
        status,
        postal_code,
        address,
        district,
        number,
        region,
        image,
        lat,
        lng,
        id
    }
}

export default withTranslation()(withRouter(connect(mapStateToProps, {
    reverseCoordinates,
    searchAddress,
    editCompany,
    findByIdCompany
})(
    GoogleApiWrapper({
        apiKey: google_maps.token,
        LoadingContainer: LoadingContainer,
        v: "3",
    })(Index)
)))

