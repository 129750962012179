import { takeEvery, put, call, takeLatest } from "redux-saga/effects"
import { NotificationManager } from "react-notifications";
import {
    UPLOAD_PRODUCTS,
    CREATE_PRODUCTS,
    EDIT_PRODUCTS,
    FIND_BY_ID_PRODUCTS
} from "./actionTypes"


import {
    createProductsSuccess,
    editProductsSuccess,
    findProductsIdSuccess,
    uploadProductsSuccess,
    apiCreateOrEditProductsError
} from "./actions"


import {
    putEditProducts,
    postCreateProducts,
    getProductsById,
    postUploadProducts
 
} from "../../helpers/products_helper"

function* findByIdProducts({ payload: { id } }) {
    try {

        const response = yield call(getProductsById, id)

        yield put(findProductsIdSuccess(response))

    } catch (error) {
        yield put(apiCreateOrEditProductsError(error))
    }
}

function* createProducts({ payload: { params, existsFiles, history } }) {
    try {

        const response = yield call(postCreateProducts, params)
        const { message, id, error } = response;

        yield put(createProductsSuccess({ message, id, error, existsFiles }))


        NotificationManager.info(message, 'Aviso');

        if (!existsFiles) {
            history.goBack()
        }

    } catch (error) {
        yield put(apiCreateOrEditProductsError(error))
    }
}

function* editProducts({ payload: { params, existsFiles, files, history } }) {
    try {

        const response = yield call(putEditProducts, params)

        yield put(editProductsSuccess(response))

        const { message } = response;
        NotificationManager.info(message, 'Aviso');
        if (!existsFiles) {
            history.goBack()
        }


    } catch (error) {
        yield put(apiCreateOrEditProductsError(error))
    }
}

function* uploadProducts({payload: {dataForm,  history}}) {
    try {

        const response = yield call(postUploadProducts, dataForm)

        yield put(uploadProductsSuccess(response))

        const {message} = response;
        NotificationManager.info(message, 'Aviso');
        

    } catch (error) {
        yield put(apiCreateOrEditProductsError(error))
    }
}



function* createOrEditProductsSaga() {
    yield takeEvery(CREATE_PRODUCTS, createProducts)
    yield takeEvery(EDIT_PRODUCTS, editProducts)
    yield takeEvery(FIND_BY_ID_PRODUCTS, findByIdProducts)
    yield takeEvery(UPLOAD_PRODUCTS, uploadProducts)
}

export default createOrEditProductsSaga
