import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

import Login from "./auth/login/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"

import Companies from "./companies/table/reducer"
import CreateOrEditCompanies from "./companies/reducer"

import CompaniesScheduler from "./companies/options/scheduler-company/table/reducer"
import CreateOrEditCompaniesScheduler from "./companies/options/scheduler-company/reducer"

import Customers from "./customers/table/reducer"
import CreateOrEditCustomer from "./customers/reducer"

import Products from "./products/table/reducer"
import CreateOrEditProduct from "./products/reducer"

import ProductsPriceList from "./productsPriceList/table/reducer"
import CreateOrEditProductsPriceList from "./productsPriceList/reducer"

import PriceListComission from "./priceComissionList/table/reducer"
import CreateOrEditPriceListComission from "./priceComissionList/reducer"

import LevelListComission from "./levelComissionList/table/reducer"
import CreateOrEditLevelListComission from "./levelComissionList/reducer"

import LoyaltyTransaction from "./loyaltyTransaction/table/reducer"
import CreateOrEditLoyaltyTransaction from "./loyaltyTransaction/reducer"

import GeoLocation from "./search/location/reducer"
import SearchCompanies from "./search/companies/reducer"

import Country from "./country/table/reducer"
import CreateOrEditCountry from "./country/reducer"
import State from "./state/table/reducer"
import CreateOrEditState from "./state/reducer"
import City from "./city/table/reducer"
import CreateOrEditCity from "./city/reducer"

import Setting from "./settings/table/reducer"
import CreateOrEditSetting from "./settings/reducer"


const rootReducer = combineReducers({
    Layout,
    Login,
    ForgetPassword,
    Companies,
    CreateOrEditCompanies,
    GeoLocation,
    SearchCompanies,

    CompaniesScheduler,
    CreateOrEditCompaniesScheduler,

    Country,
    CreateOrEditCountry,
    State,
    CreateOrEditState,
    City,
    CreateOrEditCity,
    Setting,
    CreateOrEditSetting,

    CreateOrEditCustomer,
    Customers,

    CreateOrEditProduct,
    Products,

    PriceListComission,
    CreateOrEditPriceListComission,

    LevelListComission,
    CreateOrEditLevelListComission,

    LoyaltyTransaction,
    CreateOrEditLoyaltyTransaction,

    ProductsPriceList,
    CreateOrEditProductsPriceList

})

export default rootReducer
