import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_LOYALTY_TRANSACTION,
    FILTER_PAGINATE_LOYALTY_TRANSACTION,
    DELETE_LOYALTY_TRANSACTION,
} from "./actionTypes"

import {
    allLoyaltyTransactionPaginateSuccess,
    deleteLoyaltyTransactionSuccess,
    apiPaginateLoyaltyTransactionError
} from "./actions"


import {
    getLoyaltyTransactionPaginate,
    delLoyaltyTransaction
} from "../../../helpers/loyalty_transaction_helper"
import {NotificationManager} from "react-notifications";

function* deleteLoyaltyTransaction({payload: {id, history}}) {
    try {

        const response = yield call(delLoyaltyTransaction, id)

        yield put(deleteLoyaltyTransactionSuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateLoyaltyTransactionError(error))
    }
}

function* paginateLoyaltyTransaction({payload: {params}}) {
    try {

        const response = yield call(getLoyaltyTransactionPaginate, params)

        yield put(allLoyaltyTransactionPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateLoyaltyTransactionError(error))
    }
}



function* LoyaltyTransactionSaga() {
    yield takeEvery(PAGINATE_LOYALTY_TRANSACTION, paginateLoyaltyTransaction)
    yield takeEvery(DELETE_LOYALTY_TRANSACTION, deleteLoyaltyTransaction)
}

export default LoyaltyTransactionSaga
