import {
    PAGINATE_LOYALTY_TRANSACTION,
    PAGINATE_LOYALTY_TRANSACTION_SUCCESS,
    FILTER_PAGINATE_LOYALTY_TRANSACTION,
    FILTER_PAGINATE_LOYALTY_TRANSACTION_SUCCESS,
    DELETE_LOYALTY_TRANSACTION_SUCCESS,
    DELETE_LOYALTY_TRANSACTION,
    API_ERROR,
} from "./actionTypes"


export const filtersAllLoyaltyTransactionPaginate = params => {
    return {
        type: FILTER_PAGINATE_LOYALTY_TRANSACTION,
        payload: {params},
    }
}

export const filtersAllLoyaltyTransactionPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_LOYALTY_TRANSACTION_SUCCESS,
        payload: {params},
    }
}

export const allLoyaltyTransactionPaginate = params => {
    return {
        type: PAGINATE_LOYALTY_TRANSACTION,
        payload: {params},
    }
}

export const allLoyaltyTransactionPaginateSuccess = result => {
    return {
        type: PAGINATE_LOYALTY_TRANSACTION_SUCCESS,
        payload: {result},
    }
}

export const deleteLoyaltyTransaction = (id, history) => {
  return {
    type: DELETE_LOYALTY_TRANSACTION,
    payload: {id, history} ,
  }
}

export const deleteLoyaltyTransactionSuccess = message => {
    return {
        type: DELETE_LOYALTY_TRANSACTION_SUCCESS,
        payload: message,
    }
}

export const apiPaginateLoyaltyTransactionError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

