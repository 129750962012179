export const PAGINATE_CUSTOMER = "PAGINATE_CUSTOMER"
export const PAGINATE_CUSTOMER_SUCCESS = "PAGINATE_CUSTOMER_SUCCESS"

export const FILTER_PAGINATE_CUSTOMER = "FILTER_PAGINATE_CUSTOMER"
export const FILTER_PAGINATE_CUSTOMER_SUCCESS = "FILTER_PAGINATE_CUSTOMER_SUCCESS"

export const DELETE_CUSTOMER = "DELETE_CUSTOMER"
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS"

export const API_ERROR = "PAGINATE_CUSTOMER_API_ERROR"
