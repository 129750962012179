import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_PRODUCTS_PRICE_LIST,
    FILTER_PAGINATE_PRODUCTS_PRICE_LIST,
    DELETE_PRODUCTS_PRICE_LIST,
} from "./actionTypes"

import {
    allProductsPriceListPaginateSuccess,
    deleteProductsPriceListSuccess,
    apiPaginateProductsPriceListError
} from "./actions"


import {
    getProductsPriceListPaginate,
    delProductsPriceList
} from "../../../helpers/products_price_list_helper"
import {NotificationManager} from "react-notifications";

function* deleteProductsPriceList({payload: {id, history}}) {
    try {

        const response = yield call(delProductsPriceList, id)

        yield put(deleteProductsPriceListSuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateProductsPriceListError(error))
    }
}

function* paginateProductsPriceList({payload: {params}}) {
    try {

        const response = yield call(getProductsPriceListPaginate, params)

        yield put(allProductsPriceListPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateProductsPriceListError(error))
    }
}



function* ProductsPriceListSaga() {
    yield takeEvery(PAGINATE_PRODUCTS_PRICE_LIST, paginateProductsPriceList)
    yield takeEvery(DELETE_PRODUCTS_PRICE_LIST, deleteProductsPriceList)
}

export default ProductsPriceListSaga
