export const CREATE_PRICE_COMISSION_LIST = "CREATE_PRICE_COMISSION_LIST"
export const CREATE_PRICE_COMISSION_LIST_SUCCESS = "CREATE_PRICE_COMISSION_LIST_SUCCESS"

export const EDIT_PRICE_COMISSION_LIST = "EDIT_PRICE_COMISSION_LIST"
export const EDIT_PRICE_COMISSION_LIST_SUCCESS = "EDIT_PRICE_COMISSION_LIST_SUCCESS"

export const FIND_BY_ID_PRICE_COMISSION_LIST = "FIND_BY_ID_PRICE_COMISSION_LIST"
export const FIND_BY_ID_PRICE_COMISSION_LIST_SUCCESS = "FIND_BY_ID_PRICE_COMISSION_LIST_SUCCESS"

export const CREATE_OR_EDIT_PRICE_COMISSION_LIST_API_ERROR = "CREATE_OR_EDIT_PRICE_COMISSION_LIST_API_ERROR"
