import {
    CREATE_PRODUCTS,
    CREATE_PRODUCTS_SUCCESS,
    EDIT_PRODUCTS,
    EDIT_PRODUCTS_SUCCESS,
    UPLOAD_PRODUCTS,
    UPLOAD_PRODUCTS_SUCCESS,
    FIND_BY_ID_PRODUCTS,
    FIND_BY_ID_PRODUCTS_SUCCESS,
    CREATE_OR_EDIT_PRODUCTS_API_ERROR
} from "./actionTypes"


const initialState = {
    loading: false,
    loading_form: false,
    existsFiles: false,
    error: false,
    message: '',

    sku: '',
    slug: '',
    ean: '',
    name: '',
    description: '',
    status: 1,
    image: '',
    url_image: '',
    is_service: 0,
    id: 0,

}

const createOrEditProducts = (state = initialState, action) => {

    switch (action.type) {
        case UPLOAD_PRODUCTS:
        case CREATE_PRODUCTS:
            state = {
                ...state,
                loading: true
            }
            break
        case UPLOAD_PRODUCTS_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
        case CREATE_PRODUCTS_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                id: action.payload.id,
                existsFiles: action.payload.existsFiles,
                loading: false
            }
            break
        case EDIT_PRODUCTS:
            state = { ...state, loading: true }
            break
        case EDIT_PRODUCTS_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case FIND_BY_ID_PRODUCTS:
            state = { ...state, loading: false, loading_form: true }
            break
        case FIND_BY_ID_PRODUCTS_SUCCESS:
            state = {
                ...state,
                loading: false,
                loading_form: false,

                sku: action.payload.result.sku,
                slug: action.payload.result.slug,
                ean: action.payload.result.ean,
                name: action.payload.result.name,
                description: action.payload.result.description,
                status: action.payload.result.status,
                image: action.payload.result.image,
                url_image: action.payload.result.url_image,
                is_service: action.payload.result.is_service,
                id: action.payload.result.id,

            }
        case CREATE_OR_EDIT_PRODUCTS_API_ERROR:
            state = { ...state, loading_form: false, error: action.payload, loading: false, existsFiles: false }
            break
        default:
            state = { ...state, loading_form: false, loading: false }
            break
    }

    return state
}
export default createOrEditProducts
