import {
    PAGINATE_PRICE_COMISSION_LIST,
    PAGINATE_PRICE_COMISSION_LIST_SUCCESS,
    FILTER_PAGINATE_PRICE_COMISSION_LIST,
    FILTER_PAGINATE_PRICE_COMISSION_LIST_SUCCESS,
    DELETE_PRICE_COMISSION_LIST_SUCCESS,
    DELETE_PRICE_COMISSION_LIST,
    API_ERROR,
} from "./actionTypes"


export const filtersAllPriceComissionListPaginate = params => {
    return {
        type: FILTER_PAGINATE_PRICE_COMISSION_LIST,
        payload: {params},
    }
}

export const filtersAllPriceComissionListPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_PRICE_COMISSION_LIST_SUCCESS,
        payload: {params},
    }
}

export const allPriceComissionListPaginate = params => {
    return {
        type: PAGINATE_PRICE_COMISSION_LIST,
        payload: {params},
    }
}

export const allPriceComissionListPaginateSuccess = result => {
    return {
        type: PAGINATE_PRICE_COMISSION_LIST_SUCCESS,
        payload: {result},
    }
}

export const deletePriceComissionList = (id, history) => {
  return {
    type: DELETE_PRICE_COMISSION_LIST,
    payload: {id, history} ,
  }
}

export const deletePriceComissionListSuccess = message => {
    return {
        type: DELETE_PRICE_COMISSION_LIST_SUCCESS,
        payload: message,
    }
}

export const apiPaginatePriceComissionListError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

