import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import { NotificationManager } from "react-notifications";


const getCustomerById = data => get(url.GET_CUSTOMER_BY_ID.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delCustomer = data => del(url.DELETE_CUSTOMER.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateCustomer = data => post(url.POST_CREATE_CUSTOMER, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditCustomer = data => put(url.PUT_EDIT_CUSTOMER, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getCustomersPaginate = ({ page, limit }) => get(url.GET_CUSTOMER_PAGINATE, { params: { page, limit } })
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })



export {
    getCustomersPaginate,
    putEditCustomer,
    postCreateCustomer,
    delCustomer,
    getCustomerById,
    
}
