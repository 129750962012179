import { takeEvery, put, call, takeLatest } from "redux-saga/effects"
import { NotificationManager } from "react-notifications";
import {
    CREATE_PRODUCTS_PRICE_LIST,
    EDIT_PRODUCTS_PRICE_LIST,
    FIND_BY_ID_PRODUCTS_PRICE_LIST
} from "./actionTypes"


import {
    createProductsPriceListSuccess,
    editProductsPriceListSuccess,
    findProductsPriceListIdSuccess,
    apiCreateOrEditProductsPriceListError
} from "./actions"


import {
    putEditProductsPriceList,
    postCreateProductsPriceList,
    getProductsPriceListById,

} from "../../helpers/products_price_list_helper"

function* findByIdProductsPriceList({ payload: { id } }) {
    try {

        const response = yield call(getProductsPriceListById, id)

        yield put(findProductsPriceListIdSuccess(response))

    } catch (error) {
        yield put(apiCreateOrEditProductsPriceListError(error))
    }
}

function* createProductsPriceList({ payload: { params,  history } }) {
    try {

        const response = yield call(postCreateProductsPriceList, params)
        const { message, id, error } = response;

        yield put(createProductsPriceListSuccess({ message, id, error }))


        NotificationManager.info(message, 'Aviso');

        history.goBack()

    } catch (error) {
        yield put(apiCreateOrEditProductsPriceListError(error))
    }
}

function* editProductsPriceList({ payload: { params,  history } }) {
    try {

        const response = yield call(putEditProductsPriceList, params)

        yield put(editProductsPriceListSuccess(response))

        const { message } = response;
        NotificationManager.info(message, 'Aviso');
        history.goBack()


    } catch (error) {
        yield put(apiCreateOrEditProductsPriceListError(error))
    }
}


function* createOrEditProductsPriceListSaga() {
    yield takeEvery(CREATE_PRODUCTS_PRICE_LIST, createProductsPriceList)
    yield takeEvery(EDIT_PRODUCTS_PRICE_LIST, editProductsPriceList)
    yield takeEvery(FIND_BY_ID_PRODUCTS_PRICE_LIST, findByIdProductsPriceList)
}

export default createOrEditProductsPriceListSaga
