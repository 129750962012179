export const PAGINATE_PRICE_COMISSION_LIST = "PAGINATE_PRICE_COMISSION_LIST"
export const PAGINATE_PRICE_COMISSION_LIST_SUCCESS = "PAGINATE_PRICE_COMISSION_LIST_SUCCESS"

export const FILTER_PAGINATE_PRICE_COMISSION_LIST = "FILTER_PAGINATE_PRICE_COMISSION_LIST"
export const FILTER_PAGINATE_PRICE_COMISSION_LIST_SUCCESS = "FILTER_PAGINATE_PRICE_COMISSION_LIST_SUCCESS"

export const DELETE_PRICE_COMISSION_LIST = "DELETE_PRICE_COMISSION_LIST"
export const DELETE_PRICE_COMISSION_LIST_SUCCESS = "DELETE_PRICE_COMISSION_LIST_SUCCESS"

export const API_ERROR = "PAGINATE_PRICE_COMISSION_LIST_API_ERROR"
