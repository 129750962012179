export const PAGINATE_PRODUCTS_PRICE_LIST = "PAGINATE_PRODUCTS_PRICE_LIST"
export const PAGINATE_PRODUCTS_PRICE_LIST_SUCCESS = "PAGINATE_PRODUCTS_PRICE_LIST_SUCCESS"

export const FILTER_PAGINATE_PRODUCTS_PRICE_LIST = "FILTER_PAGINATE_PRODUCTS_PRICE_LIST"
export const FILTER_PAGINATE_PRODUCTS_PRICE_LIST_SUCCESS = "FILTER_PAGINATE_PRODUCTS_PRICE_LIST_SUCCESS"

export const DELETE_PRODUCTS_PRICE_LIST = "DELETE_PRODUCTS_PRICE_LIST"
export const DELETE_PRODUCTS_PRICE_LIST_SUCCESS = "DELETE_PRODUCTS_PRICE_LIST_SUCCESS"

export const API_ERROR = "PAGINATE_PRODUCTS_PRICE_LIST_API_ERROR"
