import React from 'react';
import MetaTags from 'react-meta-tags'
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumbs from "../../../components/Breadcrumb";
import { connect } from "react-redux";
import { editProducts, findByIdProducts, uploadProducts } from "../../../store/actions"
import { AvField, AvForm } from "availity-reactstrap-validation"
import ButtonCustom from "../../../components/ButtonCustom";
import Dropzone from "react-dropzone";

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            status: 1,
            image: null

        }

        this.handleValidSubmit = this.handleValidSubmit.bind(this)
        this.handleFields = this.handleFields.bind(this)
        this.handleDrop = this.handleDrop.bind(this);
    }

    componentDidMount() {
        const { entity_id } = this.props.match.params
        this.props.findByIdProducts(entity_id)



    }

    handleDrop(e) {

        let reader = new FileReader();
        let file = e[0];

        reader.onloadend = () => {
            this.setState({
                file: file,
                existsFile: true,
                image: reader.result
            });
        }

        reader.readAsDataURL(file)
        const { entity_id } = this.props.match.params
        var data = new FormData();
        data.append('id', entity_id);
        data.append('type_dir_file', 'image');
        data.append('files', file);
        data.append('path_file_before', this.state.path_file_before_image !== '' ? this.state.path_file_before_image : this.props.image);
        this.props.uploadProducts(data)

    }


    handleFields(event) {

        const { value } = event.target

        this.setState({ status: value })
    }

    handleValidSubmit(event, values) {
        this.props.editProducts({
            id: this.props.id,
            sku: values.sku,
            ean: values.ean,
            name: values.name,
            status: this.state.status,
            description: values.description,
            is_service: this.state.is_service
        }, this.props.history)

    }

    render() {
        const { url_image } = this.props;

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Cadastro de Produto</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title="Produto"
                            breadcrumbItem="Cadastro" />
                        <Row>
                            <Col sm={8}>
                                <Card>
                                    <CardBody>
                                        <Row className="align-items-md-center  mb-3">
                                            <Col className="inner-custom-pagination d-flex">
                                                <div className="d-inline">
                                                    <span className="h4 card-title">Formulário</span>
                                                    <p className="card-title-desc mt-2">Preencha os campos abaixo, para
                                                        concluir o cadastro.</p>
                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <AvForm
                                                    className="form-horizontal"
                                                    onValidSubmit={this.handleValidSubmit}>

                                                    <Row>
                                                        <Col sm={2}>
                                                            <AvField
                                                                name="sku"
                                                                label="SKU"
                                                                type="text"
                                                                value={this.state.sku ? this.state.sku : this.props.sku}
                                                                required
                                                            />
                                                        </Col>
                                                        <Col sm={6} className="mb-2">
                                                            <AvField
                                                                name="ean"
                                                                label="EAN"
                                                                type="text"
                                                                value={this.state.ean ? this.state.ean : this.props.ean}
                                                                required
                                                            />
                                                        </Col>
                                                        <Col sm={4} className="mb-2"></Col>
                                                        <Col sm={8} className="mb-2">
                                                            <AvField
                                                                name="name"
                                                                label="Nome"
                                                                className="form-control"
                                                                placeholder="Digite o nome"
                                                                value={this.state.name ? this.state.name : this.props.name}
                                                                type="text"
                                                                required
                                                            />
                                                        </Col>
                                                        <Col sm={4}>
                                                            <AvField
                                                                value={this.state.status ? this.state.status : this.props.status} onChange={this.handleFields}
                                                                type="select" name="select"
                                                                label={this.props.t("Status")}>
                                                                <option value="1">Ativo</option>
                                                                <option value="0">Inativo</option>
                                                            </AvField>
                                                        </Col>
                                                        <Col sm={12}>
                                                            <textarea
                                                                name="description"
                                                                className="form-control"
                                                                value={this.state.description ? this.state.description : this.props.description}
                                                                placeholder="Digite uma descrição"
                                                                rows={4}
                                                                required
                                                            />
                                                        </Col>



                                                        <Col sm={12} className="pt-3">
                                                            <ButtonCustom
                                                                isLoading={this.props.loading}
                                                                size="sm"
                                                                clazzContainer="text-center"
                                                                clazz="btn btn-info w-md"
                                                                message_loaging="">
                                                                <button
                                                                    className="btn btn-info w-md"
                                                                    type="submit">
                                                                    Salvar
                                                                </button>
                                                            </ButtonCustom>
                                                        </Col>
                                                    </Row>
                                                </AvForm>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm={4}>
                                <Card>
                                    <CardBody>
                                        <Row className="align-items-md-center  mb-3">
                                            <Col className="inner-custom-pagination d-flex">
                                                <div className="d-inline">
                                                    <span className="h4 card-title">Imagem</span>
                                                </div>
                                                <div className="text-md-right ms-auto">
                                                    <button
                                                        onClick={() => {
                                                            this.props.history.goBack()
                                                        }}
                                                        className="btn btn-dark btn-sm text-light"
                                                    >
                                                        <i className="bx bx-left-arrow-circle" />
                                                        <span> Voltar</span>
                                                    </button>
                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <div className='mt-3 text-center'>
                                                    <Dropzone onDrop={this.handleDrop}>

                                                        {({ getRootProps, getInputProps }) => (

                                                            <div {...getRootProps({ className: "dropzone" })}>
                                                                <input {...getInputProps()} />
                                                                {this.props.url_image
                                                                    ?
                                                                    <img className="thumbnail img-responsive img-icon-prod"
                                                                        src={this.props.url_image ? this.state.image ? this.state.image : this.props.url_image : this.props.url_image} />
                                                                    : <p style={{ marginTop: '40%' }}
                                                                        className="text-center">
                                                                        Arraste e solte ou  clique para adicionar a imagem
                                                                    </p>
                                                                }

                                                            </div>

                                                        )}

                                                    </Dropzone>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>


        )
    }


}

Index.propTypes = {
    t: PropTypes.any,

    loading: PropTypes.any,
    error: PropTypes.any,
    message: PropTypes.any,

    sku: PropTypes.any,
    slug: PropTypes.any,
    ean: PropTypes.any,
    name: PropTypes.any,
    description: PropTypes.any,
    image: PropTypes.any,
    type_dir_file: PropTypes.any,
    url_image: PropTypes.any,
    status: PropTypes.any,
    is_service: PropTypes.any,
    id: PropTypes.any,


    editProducts: PropTypes.func,
    findByIdProducts: PropTypes.func,
    uploadProducts: PropTypes.func,

}


const mapStateToProps = state => {

    const {
        loading,
        error,
        message,
        sku,
        slug,
        ean,
        name,
        description,
        image,
        type_dir_file,
        url_image,
        status,
        is_service,
        id,
    } = state.CreateOrEditProduct
    return {
        loading,
        error,
        message,
        sku,
        slug,
        ean,
        name,
        description,
        image,
        type_dir_file,
        url_image,
        status,
        is_service,
        id,
    }
}

export default withTranslation()(withRouter(
    connect(mapStateToProps, {
        editProducts,
        findByIdProducts,
        uploadProducts

    })(Index)
))

