export const CREATE_LEVEL_COMISSION_LIST = "CREATE_LEVEL_COMISSION_LIST"
export const CREATE_LEVEL_COMISSION_LIST_SUCCESS = "CREATE_LEVEL_COMISSION_LIST_SUCCESS"

export const EDIT_LEVEL_COMISSION_LIST = "EDIT_LEVEL_COMISSION_LIST"
export const EDIT_LEVEL_COMISSION_LIST_SUCCESS = "EDIT_LEVEL_COMISSION_LIST_SUCCESS"

export const FIND_BY_ID_LEVEL_COMISSION_LIST = "FIND_BY_ID_LEVEL_COMISSION_LIST"
export const FIND_BY_ID_LEVEL_COMISSION_LIST_SUCCESS = "FIND_BY_ID_LEVEL_COMISSION_LIST_SUCCESS"

export const CREATE_OR_EDIT_LEVEL_COMISSION_LIST_API_ERROR = "CREATE_OR_EDIT_LEVEL_COMISSION_LIST_API_ERROR"
