import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_PRICE_COMISSION_LIST,
    FILTER_PAGINATE_PRICE_COMISSION_LIST,
    DELETE_PRICE_COMISSION_LIST,
} from "./actionTypes"

import {
    allPriceComissionListPaginateSuccess,
    deletePriceComissionListSuccess,
    apiPaginatePriceComissionListError
} from "./actions"


import {
    getPriceComissionListPaginate,
    delPriceComissionList
} from "../../../helpers/price_comission_list_helper"
import {NotificationManager} from "react-notifications";

function* deletePriceComissionList({payload: {id, history}}) {
    try {

        const response = yield call(delPriceComissionList, id)

        yield put(deletePriceComissionListSuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginatePriceComissionListError(error))
    }
}

function* paginatePriceComissionList({payload: {params}}) {
    try {

        const response = yield call(getPriceComissionListPaginate, params)

        yield put(allPriceComissionListPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginatePriceComissionListError(error))
    }
}



function* PriceComissionListSaga() {
    yield takeEvery(PAGINATE_PRICE_COMISSION_LIST, paginatePriceComissionList)
    yield takeEvery(DELETE_PRICE_COMISSION_LIST, deletePriceComissionList)
}

export default PriceComissionListSaga
