import {
    CREATE_CUSTOMERS,
    CREATE_CUSTOMERS_SUCCESS,
    EDIT_CUSTOMERS,
    EDIT_CUSTOMERS_SUCCESS,
    FIND_BY_ID_CUSTOMERS,
    FIND_BY_ID_CUSTOMERS_SUCCESS,
    CREATE_OR_EDIT_CUSTOMERS_API_ERROR
} from "./actionTypes"


const initialState = {
    loading: false,
    loading_form: false,
    existsFiles: false,
    error: false,
    message: '',

    f_name: '',
    l_name: '',
    document: '',
    email: '',
    phone: '',
    status: 1,
    user_id: 0,
    loyalty_point: 0,
    referral_code: '',
    referred_by: '',
    id: 0,

}

const createOrEditCustomers = (state = initialState, action) => {

    switch (action.type) {
        case CREATE_CUSTOMERS:
            state = {
                ...state,
                loading: true
            }
            break
        case CREATE_CUSTOMERS_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                id: action.payload.id,
                existsFiles: action.payload.existsFiles,
                loading: false
            }
            break
        case EDIT_CUSTOMERS:
            state = { ...state, loading: true }
            break
        case EDIT_CUSTOMERS_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case FIND_BY_ID_CUSTOMERS:
            state = { ...state, loading: false, loading_form: true }
            break
        case FIND_BY_ID_CUSTOMERS_SUCCESS:
            state = {
                ...state,
                loading: false,
                loading_form: false,

                f_name: action.payload.result.f_name,
                l_name: action.payload.result.l_name,
                document: action.payload.result.document,
                email: action.payload.result.email,
                phone: action.payload.result.phone,
                status: action.payload.result.status,
                loyalty_point: action.payload.result.loyalty_point,
                referral_code: action.payload.result.referral_code,
                referred_by: action.payload.result.referred_by,
                id: action.payload.result.id,

            }
            case CREATE_OR_EDIT_CUSTOMERS_API_ERROR:
            state = { ...state, loading_form: false, error: action.payload, loading: false, existsFiles: false }
            break
        default:
            state = { ...state, loading_form: false, loading: false }
            break
    }

    return state
}
export default createOrEditCustomers
