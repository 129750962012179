import {
    CREATE_LOYALTY_TRANSACTION,
    CREATE_LOYALTY_TRANSACTION_SUCCESS,
    EDIT_LOYALTY_TRANSACTION,
    EDIT_LOYALTY_TRANSACTION_SUCCESS,
    FIND_BY_ID_LOYALTY_TRANSACTION,
    FIND_BY_ID_LOYALTY_TRANSACTION_SUCCESS,
    CREATE_OR_EDIT_LOYALTY_TRANSACTION_API_ERROR
} from "./actionTypes"


export const createLoyaltyTransaction = (params, existsFiles,   history) => {
    return {
        type: CREATE_LOYALTY_TRANSACTION,
        payload: {params,existsFiles,  history},
    }
}

export const createLoyaltyTransactionSuccess = params => {
    return {
        type: CREATE_LOYALTY_TRANSACTION_SUCCESS,
        payload: params,
    }
}

export const editLoyaltyTransaction = (params, history) => {
    return {
        type: EDIT_LOYALTY_TRANSACTION,
        payload: {params, history},
    }
}

export const editLoyaltyTransactionSuccess = result => {
    return {
        type: EDIT_LOYALTY_TRANSACTION_SUCCESS,
        payload: {result},
    }
}

export const findByIdLoyaltyTransaction = id => {
    return {
        type: FIND_BY_ID_LOYALTY_TRANSACTION,
        payload: {id},
    }
}

export const findLoyaltyTransactionIdSuccess = result => {
    return {
        type: FIND_BY_ID_LOYALTY_TRANSACTION_SUCCESS,
        payload: result,
    }
}

export const apiCreateOrEditLoyaltyTransactionError = error => {
    return {
        type: CREATE_OR_EDIT_LOYALTY_TRANSACTION_API_ERROR,
        payload: error,
    }
}

