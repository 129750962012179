import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_LEVEL_COMISSION_LIST,
    FILTER_PAGINATE_LEVEL_COMISSION_LIST,
    DELETE_LEVEL_COMISSION_LIST,
} from "./actionTypes"

import {
    allLevelComissionListPaginateSuccess,
    deleteLevelComissionListSuccess,
    apiPaginateLevelComissionListError
} from "./actions"


import {
    getLevelComissionListPaginate,
    delLevelComissionList
} from "../../../helpers/level_comission_list_helper"
import {NotificationManager} from "react-notifications";

function* deleteLevelComissionList({payload: {id, history}}) {
    try {

        const response = yield call(delLevelComissionList, id)

        yield put(deleteLevelComissionListSuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateLevelComissionListError(error))
    }
}

function* paginateLevelComissionList({payload: {params}}) {
    try {

        const response = yield call(getLevelComissionListPaginate, params)

        yield put(allLevelComissionListPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateLevelComissionListError(error))
    }
}



function* LevelComissionListSaga() {
    yield takeEvery(PAGINATE_LEVEL_COMISSION_LIST, paginateLevelComissionList)
    yield takeEvery(DELETE_LEVEL_COMISSION_LIST, deleteLevelComissionList)
}

export default LevelComissionListSaga
