import {
    CREATE_LEVEL_COMISSION_LIST,
    CREATE_LEVEL_COMISSION_LIST_SUCCESS,
    EDIT_LEVEL_COMISSION_LIST,
    EDIT_LEVEL_COMISSION_LIST_SUCCESS,
    FIND_BY_ID_LEVEL_COMISSION_LIST,
    FIND_BY_ID_LEVEL_COMISSION_LIST_SUCCESS,
    CREATE_OR_EDIT_LEVEL_COMISSION_LIST_API_ERROR
} from "./actionTypes"


export const createLevelComissionList = (params,   history) => {
    return {
        type: CREATE_LEVEL_COMISSION_LIST,
        payload: {params,  history},
    }
}

export const createLevelComissionListSuccess = params => {
    return {
        type: CREATE_LEVEL_COMISSION_LIST_SUCCESS,
        payload: params,
    }
}

export const editLevelComissionList = (params, history) => {
    return {
        type: EDIT_LEVEL_COMISSION_LIST,
        payload: {params, history},
    }
}

export const editLevelComissionListSuccess = result => {
    return {
        type: EDIT_LEVEL_COMISSION_LIST_SUCCESS,
        payload: {result},
    }
}

export const findByIdLevelComissionList = id => {
    return {
        type: FIND_BY_ID_LEVEL_COMISSION_LIST,
        payload: {id},
    }
}

export const findLevelComissionListIdSuccess = result => {
    return {
        type: FIND_BY_ID_LEVEL_COMISSION_LIST_SUCCESS,
        payload: result,
    }
}

export const apiCreateOrEditLevelComissionListError = error => {
    return {
        type: CREATE_OR_EDIT_LEVEL_COMISSION_LIST_API_ERROR,
        payload: error,
    }
}

