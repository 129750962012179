import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import { NotificationManager } from "react-notifications";


const getPriceComissionListById = data => get(url.GET_PRICE_COMISSION_LIST_BY_ID.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delPriceComissionList = data => del(url.DELETE_PRICE_COMISSION_LIST.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreatePriceComissionList = data => post(url.POST_CREATE_PRICE_COMISSION_LIST, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditPriceComissionList = data => put(url.PUT_EDIT_PRICE_COMISSION_LIST, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getPriceComissionListPaginate = ({ page, limit }) => get(url.GET_PRICE_COMISSION_LIST_PAGINATE, { params: { page, limit } })
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

export {
    getPriceComissionListPaginate,
    putEditPriceComissionList,
    postCreatePriceComissionList,
    delPriceComissionList,
    getPriceComissionListById,
    
}
