import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import { NotificationManager } from "react-notifications";


const getProductsPriceListById = data => get(url.GET_PRODUCTS_PRICE_LIST_BY_ID.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delProductsPriceList = data => del(url.DELETE_PRODUCTS_PRICE_LIST.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateProductsPriceList = data => post(url.POST_CREATE_PRODUCTS_PRICE_LIST, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditProductsPriceList = data => put(url.PUT_EDIT_PRODUCTS_PRICE_LIST, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getProductsPriceListPaginate = ({ page, limit }) => get(url.GET_PRODUCTS_PRICE_LIST_PAGINATE, { params: { page, limit } })
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

export {
    getProductsPriceListPaginate,
    putEditProductsPriceList,
    postCreateProductsPriceList,
    delProductsPriceList,
    getProductsPriceListById,
    
}
