import { takeEvery, put, call, takeLatest } from "redux-saga/effects"

import {
    PAGINATE_PRODUCTS,
    DELETE_PRODUCTS,
} from "./actionTypes"

import {
    allProductPaginateSuccess,
    deleteProductSuccess,
    apiPaginateProductError
} from "./actions"


import {
    getProductsPaginate,
    delProducts
} from "../../../helpers/products_helper"
import { NotificationManager } from "react-notifications";

function* deleteProduct({ payload: { id, history } }) {
    try {

        const response = yield call(delProducts, id)

        yield put(deleteProductSuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info(message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateProductError(error))
    }
}

function* paginateProduct({ payload: { params } }) {
    try {

        const response = yield call(getProductsPaginate, params)

        yield put(allProductPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateProductError(error))
    }
}



function* productSaga() {
    yield takeEvery(PAGINATE_PRODUCTS, paginateProduct)
    yield takeEvery(DELETE_PRODUCTS, deleteProduct)
}

export default productSaga
