import PropTypes from 'prop-types'
import React, {Component} from "react"
import {Card, CardBody, Col, Container, Row} from "reactstrap"

// Redux
import {connect} from "react-redux"
import {Link, withRouter} from "react-router-dom"

// availity-reactstrap-validation
import {AvField, AvForm} from "availity-reactstrap-validation"

// action
import {userForgetPassword} from "../../store/actions"

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.svg"
import ButtonCustom from "../../components/ButtonCustom";

class ForgetPasswordPage extends Component {
    constructor(props) {
        super(props)
        this.state = {}

        // handleValidSubmit
        this.handleValidSubmit = this.handleValidSubmit.bind(this)
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {
        this.props.userForgetPassword(values, this.props.history)
    }


    render() {
        return (
            <React.Fragment>
                <div className="account-pages my-5 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="overflow-hidden">
                                    <div className="bg-dark bg-soft">
                                        <Row>
                                            <Col className="col-7">
                                                <div className="text-dark p-4">
                                                    <h5 className="text-dark">Recuperar acesso</h5>
                                                    <p>Informe o e-mail de login no campo abaixo.</p>
                                                </div>
                                            </Col>
                                            <Col className="col-5 align-self-end">
                                                <img src={profile} alt="" className="img-fluid"/>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-0">
                                        <div>
                                            <Link to="/">
                                                <div className="avatar-md profile-user-wid mb-4">
                                                  <span className="avatar-title rounded-circle bg-light">
                                                    <img
                                                        src={logo}
                                                        alt=""
                                                        className="rounded-circle"
                                                        height="34"
                                                    />
                                                  </span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="p-2">

                                            <AvForm
                                                className="form-horizontal"
                                                onValidSubmit={this.handleValidSubmit}
                                            >
                                                <div className="mb-3">
                                                    <AvField
                                                        name="email"
                                                        label="E-mail"
                                                        className="form-control"
                                                        placeholder="Digite o email"
                                                        type="email"
                                                        required
                                                    />
                                                </div>

                                                <ButtonCustom
                                                    isLoading={this.props.loading}
                                                    size="sm"
                                                    clazzContainer="text-end"
                                                    clazz="btn btn-dark w-md"
                                                    message_loaging="">
                                                    <button
                                                        className="btn btn-dark w-md"
                                                        type="submit"
                                                    >
                                                        Recuperar
                                                    </button>
                                                </ButtonCustom>
                                            </AvForm>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-5 text-center">
                                    <p>
                                        Voltar para o{" "}
                                        <Link
                                            to="login"
                                            className="fw-medium text-dark"
                                        >
                                            Login
                                        </Link>{" "}
                                    </p>
                                    <p>
                                        © {new Date().getFullYear()}. Fabricado com{" "}
                                        <i className="mdi mdi-heart text-danger"/> por INS Eng.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

ForgetPasswordPage.propTypes = {

    loading: PropTypes.any,

    forgetError: PropTypes.any,
    forgetSuccessMsg: PropTypes.func,
    history: PropTypes.object,
    userForgetPassword: PropTypes.any
}

const mapStateToProps = state => {
    const {forgetError, forgetSuccessMsg, loading} = state.ForgetPassword
    return {forgetError, forgetSuccessMsg, loading}
}

export default withRouter(
    connect(mapStateToProps, {userForgetPassword})(ForgetPasswordPage)
)
