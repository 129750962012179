import {
    CREATE_LEVEL_COMISSION_LIST,
    CREATE_LEVEL_COMISSION_LIST_SUCCESS,
    EDIT_LEVEL_COMISSION_LIST,
    EDIT_LEVEL_COMISSION_LIST_SUCCESS,
    FIND_BY_ID_LEVEL_COMISSION_LIST,
    FIND_BY_ID_LEVEL_COMISSION_LIST_SUCCESS,
    CREATE_OR_EDIT_LEVEL_COMISSION_LIST_API_ERROR
} from "./actionTypes"


const initialState = {
    loading: false,
    loading_form: false,
    existsFiles: false,
    error: false,
    message: '',

    level: 0,
    type: 0,
    status: 1,
    price: 0,
    percentual: 0,
    p_com_list: 0,
    id: 0,

}

const createOrEditLevelComissionList = (state = initialState, action) => {

    switch (action.type) {
        case CREATE_LEVEL_COMISSION_LIST:
            state = {
                ...state,
                loading: true
            }
            break
        case CREATE_LEVEL_COMISSION_LIST_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                id: action.payload.id,
                existsFiles: action.payload.existsFiles,
                loading: false
            }
            break
        case EDIT_LEVEL_COMISSION_LIST:
            state = { ...state, loading: true }
            break
        case EDIT_LEVEL_COMISSION_LIST_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case FIND_BY_ID_LEVEL_COMISSION_LIST:
            state = { ...state, loading: false, loading_form: true }
            break
        case FIND_BY_ID_LEVEL_COMISSION_LIST_SUCCESS:
            state = {
                ...state,
                loading: false,
                loading_form: false,

                level: action.payload.result.level,
                type: action.payload.result.type,
                price: action.payload.result.price,
                percentual: action.payload.result.percentual,
                p_com_list: action.payload.result.price_list.text,
                status: action.payload.result.status,
                id: action.payload.result.id,

            }
        case CREATE_OR_EDIT_LEVEL_COMISSION_LIST_API_ERROR:
            state = { ...state, loading_form: false, error: action.payload, loading: false, existsFiles: false }
            break
        default:
            state = { ...state, loading_form: false, loading: false }
            break
    }

    return state
}
export default createOrEditLevelComissionList
