import {
    CREATE_PRODUCTS,
    CREATE_PRODUCTS_SUCCESS,
    EDIT_PRODUCTS,
    EDIT_PRODUCTS_SUCCESS,
    UPLOAD_PRODUCTS,
    UPLOAD_PRODUCTS_SUCCESS,
    FIND_BY_ID_PRODUCTS,
    FIND_BY_ID_PRODUCTS_SUCCESS,
    CREATE_OR_EDIT_PRODUCTS_API_ERROR
} from "./actionTypes"


export const createProducts = (params, existsFiles,   history) => {
    return {
        type: CREATE_PRODUCTS,
        payload: {params,existsFiles,  history},
    }
}

export const createProductsSuccess = params => {
    return {
        type: CREATE_PRODUCTS_SUCCESS,
        payload: params,
    }
}

export const editProducts = (params, history) => {
    return {
        type: EDIT_PRODUCTS,
        payload: {params, history},
    }
}

export const editProductsSuccess = result => {
    return {
        type: EDIT_PRODUCTS_SUCCESS,
        payload: {result},
    }
}

export const findByIdProducts = id => {
    return {
        type: FIND_BY_ID_PRODUCTS,
        payload: {id},
    }
}

export const findProductsIdSuccess = result => {
    return {
        type: FIND_BY_ID_PRODUCTS_SUCCESS,
        payload: result,
    }
}

export const apiCreateOrEditProductsError = error => {
    return {
        type: CREATE_OR_EDIT_PRODUCTS_API_ERROR,
        payload: error,
    }
}

export const uploadProducts = ( dataForm,  history) => {
    return {
        type: UPLOAD_PRODUCTS,
        payload: {dataForm, history},
    }
}

export const uploadProductsSuccess = result => {
    return {
        type: UPLOAD_PRODUCTS_SUCCESS,
        payload: result,
    }
}

