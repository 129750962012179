import axios from 'axios';

const api = axios.create({
    baseURL: 'https://api.troqueeganhe.com/',
    responseType: "json",

});

api.interceptors.response.use(
    response => response,
    error => Promise.reject(error)
)

export async function get(url, config = {}) {
    return await api
        .get(url, {
            ...config,
            headers: {
                NameApplication: 'BOT',
                Version: 1.0,
                AccesType: 2,
                'Authorization': localStorage.getItem("authUser") != null ? 'Bearer '.concat(JSON.parse(localStorage.getItem("authUser")).result.token) : '',
            }
        })
        .then(response => response.data)
}

export async function post(url, data, config = {}) {
    console.log('FILE UPLOAD ', data)
    return api
        .post(url, data, {
            ...config,
            headers: {
                NameApplication: 'BOT',
                Version: 1.0,
                AccesType: 2,
                Authorization: localStorage.getItem("authUser") != null ? 'Bearer '.concat(JSON.parse(localStorage.getItem("authUser")).result.token) : '',
            }
        })
        .then(response => response.data)
}

export async function put(url, data, config = {}) {
     //console.log('FILE UPLOAD ', data)
    return api
        .put(url, data, {
            ...config,
            headers: {
                NameApplication: 'BOT',
                Version: 1.0,
                AccesType: 2,
                Authorization: localStorage.getItem("authUser") != null ? 'Bearer '.concat(JSON.parse(localStorage.getItem("authUser")).result.token) : '',
            }
        })
        .then(response => response.data)
}

export async function del(url, config = {}) {
    return await api
        .delete(url, {
            ...config,
            headers: {
                NameApplication: 'BOT',
                Version: 1.0,
                AccesType: 2,
                'Authorization': localStorage.getItem("authUser") != null ? 'Bearer '.concat(JSON.parse(localStorage.getItem("authUser")).result.token) : '',
            }
        })
        .then(response => response.data)
}
